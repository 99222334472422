import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/site/Home.vue";
import Contact from "../views/site/Contact.vue";
import About from "../views/site/About.vue";
import Properties from "../views/site/Properties.vue";
import ViewProperty from "../views/site/ViewProperty.vue";
import Terms from "../views/site/Terms.vue";
import Privacy from "../views/site/Privacy.vue";
import Locations from "../views/site/Locations.vue";
import AdminLogin from "../views/admin/Login.vue";
import Admin from "../views/admin/Home.vue";
import AdminDashboard from "../views/admin/Dashboard.vue";
import AdminLocations from "../views/admin/AdminLocations.vue";
import AdminHouses from "../views/admin/AdminHouses.vue";
import AdminStudios from "../views/admin/AdminStudios.vue";
import AdminApartments from "../views/admin/AdminApartments.vue";
import AdminLand from "../views/admin/AdminLand.vue";
import AdminAdmins from "../views/admin/AdminAdmins.vue";
import AdminSettings from "../views/admin/AdminSettings.vue";
import AdminRequest from "../views/admin/AdminRequest.vue";
import AdminContacts from "../views/admin/AdminContacts.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/properties",
    name: "Properties",
    component: Properties,
  },
  {
    path: "/view-property/:id/:name",
    name: "ViewProperty",
    component: ViewProperty,
  },
  {
    path: "/terms-and-condition",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/privacy-policy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/locations",
    name: "Locations",
    component: Locations,
  },
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    redirect: "/admin/dashboard",
    children: [
      {
        path: "/admin/dashboard",
        name: "AdminDashboard",
        component: AdminDashboard,
      },
      {
        path: "/admin/locations",
        name: "AdminLocations",
        component: AdminLocations,
      },
      {
        path: "/admin/properties/houses",
        name: "AdminHouses",
        component: AdminHouses,
      },
      {
        path: "/admin/properties/studios",
        name: "AdminStudios",
        component: AdminStudios,
      },
      {
        path: "/admin/properties/apartments",
        name: "AdminApartments",
        component: AdminApartments,
      },
      {
        path: "/admin/properties/land",
        name: "AdminLand",
        component: AdminLand,
      },
      {
        path: "/admin/admins",
        name: "AdminAdmins",
        component: AdminAdmins,
      },
      {
        path: "/admin/settings",
        name: "AdminSettings",
        component: AdminSettings,
      },
      {
        path: "/admin/requests",
        name: "AdminRequest",
        component: AdminRequest,
      },
      {
        path: "/admin/contacts",
        name: "AdminContacts",
        component: AdminContacts,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
