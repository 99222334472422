<template>
  <div class="About">
    <NavBar />
    <div class="jumbotron container">
      <div class="left">
        <p v-html="$store.state.sitesettings.privacy"></p>
      </div>
    </div>
    <div class="container">
      <div class="faqs">
        <h2 class="header">Frequently Asked Questions</h2>
        <div class="faqs">
          <div
            class="faq"
            v-for="(faq, index) in $store.state.sitesettings.site_faqs"
            :key="index"
            :class="{ active: faq.active }"
            @click="faq.active = !faq.active"
          >
            <div class="question">
              <h3>{{ faq.question }}</h3>
              <i class="fas fa-chevron-down"></i>
            </div>
            <div class="answer">
              <p>{{ faq.answer }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="newsletters">
      <div class="container">
        <h2>Spend less, get more with K-Realty</h2>
        <p>
          Subscribe to our newsletter and get the best deals on your favorite
          destinations.
        </p>
        <form action="">
          <input type="email" placeholder="Enter your email" />
          <button class="primary">Subscribe</button>
        </form>
      </div>
    </div> -->
    <Footer />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
$primary_color: #0c3b7c;
$secondary_color: #00bafc;
.About {
  margin-top: 150px;
  @media (max-width: 768px) {
    margin-top: 100px;
  }
  .left {
    :deep(p > h1) {
      font-size: 1.5rem;
      @media (max-width: 768px) {
        font-size: 1.3rem;
      }
    }
    :deep(p > h2) {
      font-size: 1.3rem;
      @media (max-width: 768px) {
        font-size: 1.1rem;
      }
    }
    :deep(p > h3) {
      font-size: 1.1rem;
      @media (max-width: 768px) {
        font-size: 0.9rem;
      }
    }
    :deep(p > h4) {
      font-size: 1rem;
      @media (max-width: 768px) {
        font-size: 0.9rem;
      }
    }
    :deep(p > h5) {
      font-size: 0.9rem;
      @media (max-width: 768px) {
        font-size: 0.8rem;
      }
    }
    :deep(p > h6) {
      font-size: 0.8rem;
      @media (max-width: 768px) {
        font-size: 0.7rem;
      }
    }
    :deep(p) {
      font-size: 1rem;
      @media (max-width: 768px) {
        font-size: 0.9rem;
      }
      @media (max-width: 500px) {
        line-height: 1.8;
        font-size: 0.75rem;
      }
    }
  }
}
</style>
