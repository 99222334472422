<template>
  <div class="contact">
    <NavBar />
    <div class="contact-section container">
      <div class="left">
        <form action="" @submit.prevent="submitForm">
          <h2>What would you like to contact us about?</h2>
          <!-- email, phone -->
          <div class="input-group group-1">
            <div class="form-group radios">
              <!-- <div class="radio">
                <input type="radio" id="email" name="contact" value="email" />
                <label for="email">I'm a Customer</label>
              </div>
              <div class="radio">
                <input type="radio" id="phone" name="contact" value="phone" />
                <label for="phone">I'm a Landlord</label>
              </div>
              <div class="radio">
                <input type="radio" id="other" name="contact" value="other" />
                <label for="other">Other</label>
              </div> -->
              <v-radio-group v-model="newItem.userType">
                <div class="radio">
                  <v-radio
                    label=""
                    color="primary"
                    value="I'm a Customer"
                  ></v-radio>
                  <span>I'm a Customer</span>
                </div>
                <div class="radio">
                  <v-radio
                    label=""
                    color="primary"
                    value="I'm a Landlord"
                  ></v-radio>
                  <span>I'm a Landlord</span>
                </div>
                <div class="radio">
                  <v-radio
                    label="
                  "
                    color="primary"
                    value="Other"
                  ></v-radio>
                  <span>Other</span>
                </div>
              </v-radio-group>
            </div>
            <div class="form-group">
              <input
                type="text"
                id="name"
                placeholder="Name"
                v-model="newItem.name"
              />
            </div>
            <div class="form-group">
              <input
                type="email"
                id="email"
                placeholder="Email"
                v-model="newItem.email"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                id="phone"
                placeholder="Phone"
                v-model="newItem.phone"
              />
            </div>
            <div class="form-group">
              <textarea
                name="message"
                id="message"
                rows="5"
                placeholder="Your message"
                v-model="newItem.message"
              ></textarea>
            </div>
          </div>
          <div class="input-group">
            <button type="submit" class="btn bp cw">Submit</button>
          </div>
        </form>
      </div>
      <div class="right">
        <div class="title">
          <h1>Let's Connect</h1>
          <p>
            Our expertise in Real estate ensures cost-effective, scalable and
            rubust solutions. Contact us for your customized website today.
          </p>
          <div class="whatsapp-us">
            <a
              :href="
                'https://wa.me/' + this.$store.state.sitesettings.site_whatsapp
              "
              target="_blank"
            >
              <i class="fab fa-whatsapp"></i> WhatsApp Us
            </a>
          </div>
        </div>
        <div class="contact-item">
          <h3>Location</h3>
          <div class="text">
            <p>
              {{ $store.state.sitesettings.site_address }}
            </p>
          </div>
        </div>
        <div class="contact-item">
          <h3>Phone</h3>
          <div class="text">
            <p>{{ $store.state.sitesettings.site_phone }}</p>
          </div>
        </div>
        <div class="contact-item">
          <h3>Email</h3>
          <div class="text">
            <p>{{ $store.state.sitesettings.site_email }}</p>
          </div>
        </div>
        <div class="socials">
          <h3>Join Our Community</h3>
          <div class="icons">
            <a
              :href="this.$store.state.sitesettings.site_facebook"
              target="_blank"
              rel="noopener noreferrer"
              v-if="this.$store.state.sitesettings.site_facebook"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
            <a
              :href="this.$store.state.sitesettings.site_instagram"
              target="_blank"
              rel="noopener noreferrer"
              v-if="this.$store.state.sitesettings.site_instagram"
            >
              <i class="fab fa-instagram"></i>
            </a>
            <a
              :href="this.$store.state.sitesettings.site_twitter"
              target="_blank"
              rel="noopener noreferrer"
              v-if="this.$store.state.sitesettings.site_twitter"
            >
              <i class="fa-brands fa-x-twitter"></i>
            </a>
            <a
              :href="this.$store.state.sitesettings.site_linkedin"
              target="_blank"
              rel="noopener noreferrer"
              v-if="this.$store.state.sitesettings.site_linkedin"
            >
              <i class="fa-brands fa-linkedin-in"></i>
            </a>
            <a
              :href="this.$store.state.sitesettings.site_youtube"
              target="_blank"
              rel="noopener noreferrer"
              v-if="this.$store.state.sitesettings.site_youtube"
            >
              <i class="fa-brands fa-youtube"></i>
            </a>
            <a
              :href="this.$store.state.sitesettings.site_tiktok"
              target="_blank"
              rel="noopener noreferrer"
              v-if="this.$store.state.sitesettings.site_tiktok"
            >
              <i class="fa-brands fa-tiktok"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d255203.55263329137!2d30.12724445!3d-1.9297706000000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dca4258ed8e797%3A0xf32b36a5411d0bc8!2sKigali!5e0!3m2!1sen!2srw!4v1708441285862!5m2!1sen!2srw"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <Footer />
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  components: {},
  data() {
    return {
      newItem: {
        userType: "I'm a Customer",
        name: "",
        email: "",
        phone: "",
        message: "",
      },
    };
  },
  methods: {
    submitForm() {
      if (!this.newItem.name || !this.newItem.email || !this.newItem.phone) {
        toast.error("All fields are required");
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("CREATE_CONTACT", {
          token: this.$getAdminToken(),
          data: this.newItem,
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.showNotification = true;
              this.$stopLoader();
              this.newItem = {
                name: "",
                email: "",
                phone: "",
                message: "",
              };
              window.scrollTo(0, 0);
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
.contact {
  .contact-section {
    display: flex;
    justify-content: space-between;
    margin-top: 10rem;
    background: #fff;
    position: relative;
    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: 5rem;
    }
    .left {
      width: 40%;
      padding: 2rem 2rem;
      @media (max-width: 768px) {
        width: 100%;
      }
      form {
        h2 {
          font-size: 1.15rem;
          margin-bottom: 2rem;
          color: #000;
          @media (max-width: 768px) {
            text-align: center;
            font-size: 1rem;
            margin-bottom: 1rem;
          }
        }
        .input-group {
          margin-bottom: 2rem;
          @media (max-width: 768px) {
            margin-bottom: 1rem;
          }
          .form-group {
            input,
            select,
            textarea {
              width: 100%;
              padding: 0.6rem 1rem;
              border: 1px solid #ddd;
              border-radius: 5px;
              outline: none;
              font-size: 0.95rem;
              background: #eee;
              margin-bottom: 10px;
              transition: all 0.3s ease;
              @media (max-width: 768px) {
                font-size: 0.8rem;
              }
              @media (max-width: 500px) {
                font-size: 0.7rem;
              }
            }
            &.radios {
              .radio {
                position: relative;
                margin-bottom: 1rem;
                @media (max-width: 768px) {
                  margin-bottom: 0.1rem;
                }
                input {
                  margin-right: 1rem;
                  cursor: pointer;
                  @media (max-width: 768px) {
                    margin-right: 0.5rem;
                  }
                }
                span {
                  font-size: 1rem;
                  color: #000;
                  position: absolute;
                  top: 50%;
                  left: 2.5rem;
                  transform: translateY(-50%);
                  cursor: pointer;
                  @media (max-width: 768px) {
                    font-size: 0.8rem;
                  }
                  @media (max-width: 500px) {
                    font-size: 0.65rem;
                  }
                }
              }
            }
          }
          button {
            padding: 0.5rem 2rem;
            background-color: $primary_color;
            color: #fff;
            border: none;
            border-radius: 5px;
            font-size: 1.2rem;
            cursor: pointer;
            transition: all 0.5s ease;
            width: 100%;
            &:hover {
              background-color: $secondary_color;
            }
            @media (max-width: 768px) {
              font-size: 1rem;
            }
            @media (max-width: 500px) {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
    .right {
      width: 60%;
      padding: 1rem 3rem;
      border-radius: 10px 0px 0px 10px;
      padding-top: 2rem;
      @media (max-width: 768px) {
        width: 100%;
        padding: 1rem 2rem;
        border-radius: 10px 10px 0px 0px;
      }

      .fa-headset {
        font-size: 25rem;
        position: absolute;
        bottom: 0rem;
        left: 0rem;
        color: #ffffff0c;
      }
      .title {
        margin-bottom: 2rem;
        h1 {
          font-size: 1.15rem;
          margin-bottom: 1rem;
          color: #000;
          @media (max-width: 768px) {
            text-align: center;
            font-size: 1rem;
            margin-bottom: 0.5rem;
          }
          @media (max-width: 500px) {
            font-size: 0.9rem;
          }
        }
        p {
          font-size: 0.9rem;
          color: #000;
          @media (max-width: 768px) {
            text-align: center;
            font-size: 0.8rem;
          }
          @media (max-width: 500px) {
            font-size: 0.7rem;
          }
        }
        .whatsapp-us {
          margin-top: 2rem;
          @media (max-width: 768px) {
            margin-top: 1rem;
          }

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.5rem 1rem;
            background-color: #25d366;
            color: #fff;
            border-radius: 5px;
            font-size: 1rem;
            text-decoration: none;
            width: 200px;
            transition: all 0.3s ease;
            i {
              font-size: 1.5rem;
              margin-right: 1rem;
            }
            &:hover {
              background-color: #128c7e;
            }
            @media (max-width: 768px) {
              width: 100%;
            }
            @media (max-width: 500px) {
              font-size: 0.9rem;
            }
          }
        }
      }
      .contact-item {
        margin-bottom: 1rem;
        h3 {
          font-size: 1rem;
          margin-bottom: 0.5rem;
          color: #000;
          @media (max-width: 768px) {
            font-size: 0.9rem;
          }
          @media (max-width: 500px) {
            font-size: 0.8rem;
          }
        }
        .text {
          display: flex;
          align-items: center;
          .icon {
            font-size: 1.5rem;
            margin-right: 1rem;
            color: #000;
            @media (max-width: 768px) {
              font-size: 1.3rem;
            }
            @media (max-width: 500px) {
              font-size: 1.1rem;
            }
          }
          p {
            font-size: 1rem;
            color: #000;
            @media (max-width: 768px) {
              font-size: 0.9rem;
            }
            @media (max-width: 500px) {
              font-size: 0.8rem;
            }
          }
        }
      }
      .socials {
        margin-top: 1rem;
        h3 {
          font-size: 1rem;
          margin-bottom: 1rem;
          color: #000;
        }
        .icons {
          i {
            font-size: 1.5rem;
            margin-right: 1rem;
            color: #000;
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover {
              color: $primary_color;
            }
            @media (max-width: 768px) {
              font-size: 1.3rem;
            }
            @media (max-width: 500px) {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }
  .map {
    width: 1005;
    iframe {
      width: 100%;
      border: 0;
    }
    margin-bottom: 50px;
  }
}
</style>
