<template>
  <div class="ImagesViewer">
    <div>
      <div class="header">
        <h2>{{ iTitle }}</h2>
        <i class="fa fa-times" @click="closeMOdel"></i>
      </div>
      <div class="images-container">
        <img
          :src="$upload(image)"
          alt="image"
          @click="selectedImage = image"
          v-for="(image, index) in images"
          :key="index"
        />
      </div>
      <div class="selectedImage model" v-if="selectedImage">
        <i class="fa fa-times close" @click="selectedImage = null"></i>
        <div class="img">
          <i class="fa fa-chevron-left" @click="slide('back')"></i>
          <img :src="$upload(selectedImage)" alt="selectedImage" />
          <i class="fa fa-chevron-right" @click="slide('next')"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImagesViewer",
  props: {
    images: {
      type: Array,
      required: true,
    },
    iTitle: {
      type: String,
      default: "View Images",
    },
    activeImage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedImage: null,
    };
  },
  methods: {
    closeMOdel() {
      document.body.style.overflow = "auto";
      this.$emit("close");
    },
    slide(direction) {
      let index = this.images.indexOf(this.selectedImage);
      if (direction === "back") {
        if (index === 0) {
          this.selectedImage = this.images[this.images.length - 1];
        } else {
          this.selectedImage = this.images[index - 1];
        }
      } else {
        if (index === this.images.length - 1) {
          this.selectedImage = this.images[0];
        } else {
          this.selectedImage = this.images[index + 1];
        }
      }
    },
  },
  mounted() {
    document.body.style.overflow = "hidden";
    if (this.activeImage) {
      this.selectedImage = this.activeImage;
    }
    // on escape key press close the model
    window.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.closeMOdel();
      }
    });
    // on arrow key press slide the images
    window.addEventListener("keydown", (e) => {
      if (e.key === "ArrowLeft") {
        this.slide("back");
      }
      if (e.key === "ArrowRight") {
        this.slide("next");
      }
    });
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
.ImagesViewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  h2 {
    margin: 0;
    font-weight: 400;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: $primary_color;
  color: white;
  width: 80vw;
  margin-top: 1rem;
  border-radius: 10px 10px 0px 0px;
  i {
    cursor: pointer;
    font-size: 2rem;
  }
}
.images-container {
  display: block;
  flex-direction: row;
  width: 80vw;
  background: #fff;
  height: 80vh;
  overflow-y: auto;
  border-radius: 0px 0px 10px 10px;
  flex-wrap: wrap;
  img {
    width: 200px;
    height: 138px;
    cursor: pointer;
    border: 1px solid #eee;
    border-radius: 10px;
    display: inline-block;
    margin-left: 10px;
    margin-top: 10px;
    object-fit: cover;
  }
}
.selectedImage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  i.close {
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 1rem;
    font-size: 2rem;
    color: white;
  }
  .img {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80vw;
      height: 80vh;
      object-fit: contain;
    }
    i {
      cursor: pointer;
      font-size: 2rem;
      color: white;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 10px;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>