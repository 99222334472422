<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">
        <span>Properties / <b>Houses</b></span>
        <form class="form-search" @submit.prevent="getItems()">
          <input type="text" placeholder="Search" v-model="searchQuery" />
        </form>
        <button class="my-btn cw glow wd" @click="startAdd = true">
          Add House
        </button>
      </h1>

      <div class="my-table">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>ID</th>
              <th>
                <p>
                  Title
                  <span class="sorts">
                    <i
                      class="fas fa-arrow-up-long"
                      @click="sortNow('title', 'asc')"
                      :class="{
                        active: sortBy === 'title' && sortType === 'asc',
                      }"
                    ></i>
                    <i
                      class="fas fa-arrow-down-long"
                      @click="sortNow('title', 'desc')"
                      :class="{
                        active: sortBy === 'title' && sortType === 'desc',
                      }"
                    ></i>
                  </span>
                </p>
              </th>
              <th>
                <p>
                  Location
                  <span class="sorts">
                    <i
                      class="fas fa-arrow-up-long"
                      @click="sortNow('location', 'asc')"
                      :class="{
                        active: sortBy === 'location' && sortType === 'asc',
                      }"
                    ></i>
                    <i
                      class="fas fa-arrow-down-long"
                      @click="sortNow('location', 'desc')"
                      :class="{
                        active: sortBy === 'location' && sortType === 'desc',
                      }"
                    ></i>
                  </span>
                </p>
              </th>
              <th>
                <p>
                  Category
                  <span class="sorts">
                    <i
                      class="fas fa-arrow-up-long"
                      @click="sortNow('category', 'asc')"
                      :class="{
                        active: sortBy === 'category' && sortType === 'asc',
                      }"
                    ></i>
                    <i
                      class="fas fa-arrow-down-long"
                      @click="sortNow('category', 'desc')"
                      :class="{
                        active: sortBy === 'category' && sortType === 'desc',
                      }"
                    ></i>
                  </span>
                </p>
              </th>
              <th>
                <p>
                  Price
                  <span class="sorts">
                    <i
                      class="fas fa-arrow-up-long"
                      @click="sortNow('price', 'asc')"
                      :class="{
                        active: sortBy === 'price' && sortType === 'asc',
                      }"
                    ></i>
                    <i
                      class="fas fa-arrow-down-long"
                      @click="sortNow('price', 'desc')"
                      :class="{
                        active: sortBy === 'price' && sortType === 'desc',
                      }"
                    ></i>
                  </span>
                </p>
              </th>
              <th>
                <p>
                  features
                  <span class="sorts">
                    <i
                      class="fas fa-arrow-up-long"
                      @click="sortNow('features', 'asc')"
                      :class="{
                        active: sortBy === 'features' && sortType === 'asc',
                      }"
                    ></i>
                    <i
                      class="fas fa-arrow-down-long"
                      @click="sortNow('features', 'desc')"
                      :class="{
                        active: sortBy === 'features' && sortType === 'desc',
                      }"
                    ></i>
                  </span>
                </p>
              </th>
              <th>
                <p>
                  Type
                  <span class="sorts">
                    <i
                      class="fas fa-arrow-up-long"
                      @click="sortNow('type', 'asc')"
                      :class="{
                        active: sortBy === 'type' && sortType === 'asc',
                      }"
                    ></i>
                    <i
                      class="fas fa-arrow-down-long"
                      @click="sortNow('type', 'desc')"
                      :class="{
                        active: sortBy === 'type' && sortType === 'desc',
                      }"
                    ></i>
                  </span>
                </p>
              </th>
              <th>
                <p>
                  Status
                  <span class="sorts">
                    <i
                      class="fas fa-arrow-up-long"
                      @click="sortNow('status', 'asc')"
                      :class="{
                        active: sortBy === 'status' && sortType === 'asc',
                      }"
                    ></i>
                    <i
                      class="fas fa-arrow-down-long"
                      @click="sortNow('status', 'desc')"
                      :class="{
                        active: sortBy === 'status' && sortType === 'desc',
                      }"
                    ></i>
                  </span>
                </p>
              </th>
              <th>
                <p>
                  Size
                  <span class="sorts">
                    <i
                      class="fas fa-arrow-up-long"
                      @click="sortNow('size', 'asc')"
                      :class="{
                        active: sortBy === 'size' && sortType === 'asc',
                      }"
                    ></i>
                    <i
                      class="fas fa-arrow-down-long"
                      @click="sortNow('size', 'desc')"
                      :class="{
                        active: sortBy === 'size' && sortType === 'desc',
                      }"
                    ></i>
                  </span>
                </p>
              </th>
              <th>Added by</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in properties" :key="item.id">
              <td>
                <img :src="$upload(item.image)" alt="Location Image" />
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'ViewProperty',
                    params: {
                      id: item.property_id,
                      name: $urlCompatible(item.title),
                    },
                  }"
                  target="_blank"
                >
                  {{ item.property_idno }}
                </router-link>
              </td>
              <td>{{ item.title }}</td>
              <td v-if="item.location">{{ item.location.name }}</td>
              <td>{{ item.category }}</td>
              <td>{{ item.price }} {{ item.currency }}</td>
              <td>
                <span
                  >{{ item.features.bedrooms }} Beds, Parking:
                  {{ item.features.parking }}
                </span>
              </td>
              <td>{{ item.type }}</td>
              <td>{{ item.status }}</td>
              <td>{{ item.size }}</td>
              <td>{{ item.created_by.name }}</td>
              <td>
                <button
                  @click="
                    selectedItem = item;
                    startEdit = true;
                  "
                  class="btn edit"
                >
                  Edit
                </button>
                <button
                  @click="
                    selectedItem = item;
                    startDelete = true;
                  "
                  class="btn delete"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>Previous</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>Next</label>
          </button>
        </div>
      </div>
      <Model
        v-if="startAdd"
        @closeModel="startAdd = false"
        :modelTitle="'Add House'"
        :size="'md'"
      >
        <div class="model-body">
          <div class="form-group group-3">
            <div class="input-group">
              <label for="property_idno">Property ID</label>
              <input
                type="text"
                v-model="newItem.property_idno"
                placeholder="Property ID"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="title">Title</label>
              <input
                type="text"
                v-model="newItem.title"
                placeholder="Title"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="location">Location</label>
              <v-combobox
                v-model="newItem.location"
                :items="locations"
                :item-title="'name'"
                :item-value="'location_id'"
                density="compact"
                variant="solo"
                :placeholder="'Location'"
                clearable
              >
              </v-combobox>
            </div>
          </div>
          <div class="form-group group-3">
            <div class="input-group">
              <label for="Category">Category</label>
              <select v-model="newItem.category" class="form-control">
                <option value="House">House</option>
                <option value="Studio">Studio</option>
                <option value="Apartment">Apartment</option>
                <option value="Land">Land</option>
              </select>
            </div>
            <div class="input-group">
              <label for="Featured">Featured</label>
              <select v-model="newItem.featured" class="form-control">
                <option :value="1">Yes</option>
                <option :value="0">No</option>
              </select>
            </div>
            <div class="input-group">
              <label for="price">Price</label>
              <div class="control-group price flex">
                <select
                  name=""
                  id=""
                  v-model="newItem.currency"
                  class="form-control"
                >
                  <option value="USD">USD</option>
                  <option value="RWF">RWF</option>
                </select>
                <input
                  type="number"
                  v-model="newItem.price"
                  placeholder="Price"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="form-group group-3">
            <div class="input-group">
              <label for="status">Status</label>
              <select v-model="newItem.status" class="form-control">
                <option value="Furnished">Furnished</option>
                <option value="Unfurnished">Unfurnished</option>
              </select>
            </div>
            <div class="input-group">
              <label for="type">Type</label>
              <select v-model="newItem.type" class="form-control">
                <option value="Rent">Rent</option>
                <option value="Sale">Sale</option>
                <option value="Occupied">Occupied</option>
                <option value="Sold">Sold</option>
              </select>
            </div>
            <div class="input-group">
              <label for="size">Size</label>
              <input
                type="text"
                v-model="newItem.size"
                placeholder="50 x 35 m2"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group group-3">
            <div class="input-group">
              <label for="Sitting rooms">Sitting rooms</label>
              <input
                type="number"
                v-model="newItem.features.sitting_rooms"
                placeholder="Sitting rooms"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="Bedrooms">Bedrooms</label>
              <input
                type="number"
                v-model="newItem.features.bedrooms"
                placeholder="Bedrooms"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="Bathrooms">Bathrooms</label>
              <input
                type="number"
                v-model="newItem.features.bathrooms"
                placeholder="Bathrooms"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="Kitchen">Kitchen</label>
              <input
                type="number"
                v-model="newItem.features.kitchen"
                placeholder="Kitchen"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="Rooms">Garden</label>
              <select
                name=""
                id=""
                v-model="newItem.features.garden"
                class="form-control"
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div class="input-group">
              <label for="Parking">Parking</label>
              <input
                type="text"
                v-model="newItem.features.parking"
                placeholder="Parking"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <QuillEditor
              :toolbar="minimal"
              v-model:content="newItem.description"
              :contentType="'html'"
              theme="snow"
            />
          </div>
          <div class="form-group">
            <label for="image">Image</label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  newItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="newItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="form-group">
            <label for="images">Images</label>
            <o-upload
              :multiple="true"
              @imagesUploaded="
                (data) => {
                  newItem.images = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="newItem.images"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="form-group">
            <label for="Other info">Other info</label>
            <textarea
              v-model="newItem.otherInfo"
              placeholder="Other information"
              class="form-control"
            ></textarea>
          </div>
          <div class="btns flex">
            <button @click="startAdd = false" class="my-btn cw wd cancel">
              Cancel
            </button>
            <button @click="createItem" class="my-btn cw glow wd">Add</button>
          </div>
        </div>
      </Model>
      <Model
        v-if="startEdit"
        @closeModel="startEdit = false"
        :modelTitle="'Edit House'"
        :size="'md'"
      >
        <div class="model-body">
          <div class="form-group group-3">
            <div class="input-group">
              <label for="property_idno">Property ID</label>
              <input
                type="text"
                v-model="selectedItem.property_idno"
                placeholder="Property ID"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="title">Title</label>
              <input
                type="text"
                v-model="selectedItem.title"
                placeholder="Title"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="location">Location</label>
              <v-combobox
                v-model="selectedItem.location"
                :items="locations"
                :itemTitle="(location) => location.name"
                :itemValue="(location) => location.location_id"
                density="compact"
                variant="solo"
                :placeholder="'Location'"
                clearable
              >
              </v-combobox>
            </div>
          </div>
          <div class="form-group group-3">
            <div class="input-group">
              <label for="Category">Category</label>
              <select v-model="selectedItem.category" class="form-control">
                <option value="House">House</option>
                <option value="Studio">Studio</option>
                <option value="Apartment">Apartment</option>
                <option value="Land">Land</option>
              </select>
            </div>
            <div class="input-group">
              <label for="Featured">Featured</label>
              <select v-model="selectedItem.featured" class="form-control">
                <option :value="1">Yes</option>
                <option :value="0">No</option>
              </select>
            </div>
            <div class="input-group">
              <label for="price">Price</label>
              <div class="control-group price flex">
                <select
                  name=""
                  id=""
                  v-model="selectedItem.currency"
                  class="form-control"
                >
                  <option value="USD">USD</option>
                  <option value="RWF">RWF</option>
                </select>
                <input
                  type="number"
                  v-model="selectedItem.price"
                  placeholder="Price"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="form-group group-3">
            <div class="input-group">
              <label for="status">Status</label>
              <select v-model="selectedItem.status" class="form-control">
                <option value="Furnished">Furnished</option>
                <option value="Unfurnished">Unfurnished</option>
              </select>
            </div>
            <div class="input-group">
              <label for="type">Type</label>
              <select v-model="selectedItem.type" class="form-control">
                <option value="Rent">Rent</option>
                <option value="Sale">Sale</option>
                <option value="Occupied">Occupied</option>
                <option value="Sold">Sold</option>
              </select>
            </div>
            <div class="input-group">
              <label for="size">Size</label>
              <input
                type="text"
                v-model="selectedItem.size"
                placeholder="50 x 35 m2"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group group-3">
            <div class="input-group">
              <label for="Sitting rooms">Sitting rooms</label>
              <input
                type="number"
                v-model="selectedItem.features.sitting_rooms"
                placeholder="Sitting rooms"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="Bedrooms">Bedrooms</label>
              <input
                type="number"
                v-model="selectedItem.features.bedrooms"
                placeholder="Bedrooms"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="Bathrooms">Bathrooms</label>
              <input
                type="number"
                v-model="selectedItem.features.bathrooms"
                placeholder="Bathrooms"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="Kitchen">Kitchen</label>
              <input
                type="number"
                v-model="selectedItem.features.kitchen"
                placeholder="Kitchen"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="Rooms">Garden</label>
              <select
                name=""
                id=""
                v-model="selectedItem.features.garden"
                class="form-control"
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div class="input-group">
              <label for="Parking">Parking</label>
              <input
                type="text"
                v-model="selectedItem.features.parking"
                placeholder="Parking"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <QuillEditor
              :toolbar="minimal"
              v-model:content="selectedItem.description"
              :contentType="'html'"
              theme="snow"
            />
          </div>
          <div class="form-group">
            <label for="image">Image</label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  selectedItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="selectedItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="form-group">
            <label for="images">Images</label>
            <o-upload
              :multiple="true"
              @imagesUploaded="
                (data) => {
                  selectedItem.images = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="selectedItem.images"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="form-group">
            <label for="Other info">Other info</label>
            <textarea
              v-model="selectedItem.otherInfo"
              placeholder="Other information"
              class="form-control"
            ></textarea>
          </div>
          <div class="btns flex">
            <button @click="startEdit = false" class="my-btn cw wd cancel">
              Cancel
            </button>
            <button @click="updateItem" class="my-btn cw glow wd">
              Update
            </button>
          </div>
        </div>
      </Model>
      <confirmModel
        v-if="startDelete"
        @closeModel="startDelete = false"
        :modelTitle="'Delete House'"
        :size="'sm'"
        :displayText="'Are you sure you want to delete this House?'"
        @confirmModel="deleteItem"
      />
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      locations: [],
      newItem: {
        location_id: null,
        title: "",
        image: "",
        category: "House",
        images: [],
        price: 1,
        currency: "USD",
        features: {
          sitting_rooms: 1,
          bedrooms: 1,
          bathrooms: 1,
          kitchen: 1,
          parking: "Yes",
          garden: "Yes",
        },
        description: "",
        map: "",
        type: "Rent",
        status: "Unfurnished",
        size: "",
        property_idno: "",
        otherInfo: "",
        featured: 0,
      },
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      allItemsCount: 0,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      properties: [],
      searchQuery: "",
      sortBy: "created_at",
      sortType: "asc",
    };
  },
  methods: {
    getLocations() {
      this.$store
        .dispatch("GET_LOCATIONS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.locations = res.data.locations.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_HOUSES", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            searchQuery: this.searchQuery,
            sortBy: this.sortBy,
            sortType: this.sortType,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              if (res.data.properties) {
                this.properties = res.data.properties.data;
                this.allItemsCount = res.data.properties.total;
              }
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    createItem() {
      this.$startLoader();
      this.newItem.location_id = this.newItem.location.location_id;
      this.$store
        .dispatch("CREATE_PROPERTY", {
          data: this.newItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startAdd = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    updateItem() {
      if (this.selectedItem.title) {
        this.selectedItem.location_id = this.selectedItem.location.location_id;
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_PROPERTY", {
            data: this.selectedItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startEdit = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_PROPERTY", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    sortNow(sortBy, sortType) {
      this.sortBy = sortBy;
      this.sortType = sortType;
      this.getItems();
    },
  },
  mounted() {
    document.title = "Houses";
    this.getItems();
    this.getLocations();
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 1.5rem;
      font-weight: 600;
      b {
        color: $primary_color;
        font-weight: 600;
      }
    }
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .my-table {
      table {
        width: 100%;
        border-collapse: collapse;
        thead {
          th {
            text-align: left;
            padding: 1rem 0;
            font-weight: 600;
            border-bottom: 1px solid #e5e8ee;
          }
        }
        tbody {
          tr {
            td {
              padding: 5px;
              border-bottom: 1px solid #e5e8ee;
              img {
                width: 100px;
                height: 50px;
                object-fit: cover;
                border-radius: 5px;
              }
              button {
                margin-right: 10px;
                &.edit {
                  background-color: #1d2088;
                  color: white;
                  &:hover {
                    background-color: #1eb9ee;
                  }
                }
                &.delete {
                  background-color: #a82929;
                  color: white;
                  &:hover {
                    background-color: #7a1f1f;
                  }
                }
                &.default {
                  background-color: #1d2088;
                  color: white;
                  &:hover {
                    background-color: #1eb9ee;
                  }
                }
              }
            }
          }
        }
      }
    }
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
        &.cancel {
          background-color: #a82929;
          color: white;
          &:hover {
            background-color: #7a1f1f;
          }
        }
      }
    }
  }
}

.form-group {
  column-gap: 10px;
}
.control-group {
  display: flex;
  align-items: center;
  select {
    padding: 0.5rem;
    width: 100px;
    text-align: center;
    border-radius: 5px 0px 0px 5px;
    background: #eee;
  }
  input {
    padding: 0.5rem;
    border-radius: 0px 5px 5px 0px;
  }
}
</style>