<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">
        <span>SETTINGS</span>
      </h1>
      <div class="tabs">
        <div
          class="tab tab-1"
          :class="{ active: activeTab === 1 }"
          @click="activeTab = 1"
        >
          Site Settings
        </div>
        <div
          class="tab tab-1"
          :class="{ active: activeTab === 2 }"
          @click="activeTab = 2"
        >
          Slides
        </div>
        <div
          class="tab tab-1"
          :class="{ active: activeTab === 3 }"
          @click="activeTab = 3"
        >
          Faqs
        </div>
        <div
          class="tab tab-1"
          :class="{ active: activeTab === 4 }"
          @click="activeTab = 4"
        >
          About
        </div>
        <div
          class="tab tab-1"
          :class="{ active: activeTab === 5 }"
          @click="activeTab = 5"
        >
          Privacy Policy
        </div>
        <div
          class="tab tab-1"
          :class="{ active: activeTab === 6 }"
          @click="activeTab = 6"
        >
          Terms & Conditions
        </div>
        <div
          class="tab tab-1"
          :class="{ active: activeTab === 7 }"
          @click="activeTab = 7"
        >
          Profile
        </div>
        <div
          class="tab tab-2"
          :class="{ active: activeTab === 8 }"
          @click="activeTab = 8"
        >
          Change Password
        </div>
      </div>
      <div class="tab-content">
        <div v-if="activeTab === 1" class="tab-content-1">
          <div class="tab-header flex-btn">
            <h4>Site Settings</h4>
          </div>
          <div class="form-group group-3">
            <div class="input-group">
              <label for="name">Site Name</label>
              <input
                type="text"
                id="name"
                v-model="sitesettings.site_name"
                placeholder="Enter your name"
              />
            </div>
            <div class="input-group">
              <label for="name">Email</label>
              <input
                type="text"
                id="email"
                v-model="sitesettings.site_email"
                placeholder="Enter your email"
              />
            </div>
            <div class="input-group">
              <label for="phone">Phone</label>
              <input
                type="text"
                id="phone"
                v-model="sitesettings.site_phone"
                placeholder="Enter your phone"
              />
            </div>
          </div>
          <div class="form-group group-4">
            <div class="input-group">
              <label for="facebook">Facebook</label>
              <input
                type="text"
                id="facebook"
                v-model="sitesettings.site_facebook"
                placeholder="Enter your facebook"
              />
            </div>
            <div class="input-group">
              <label for="twitter">Twitter</label>
              <input
                type="text"
                id="twitter"
                v-model="sitesettings.site_twitter"
                placeholder="Enter your twitter"
              />
            </div>
            <div class="input-group">
              <label for="instagram">Instagram</label>
              <input
                type="text"
                id="instagram"
                v-model="sitesettings.site_instagram"
                placeholder="Enter your instagram"
              />
            </div>
            <div class="input-group">
              <label for="instagram">Tiktok</label>
              <input
                type="text"
                id="instagram"
                v-model="sitesettings.site_tiktok"
                placeholder="Enter your tiktok"
              />
            </div>
          </div>
          <div class="form-group group-4">
            <div class="input-group">
              <label for="linkedin">Linkedin</label>
              <input
                type="text"
                id="linkedin"
                v-model="sitesettings.site_linkedin"
                placeholder="Enter your linkedin"
              />
            </div>
            <div class="input-group">
              <label for="pinterest">Pinterest</label>
              <input
                type="text"
                id="pinterest"
                v-model="sitesettings.site_pinterest"
                placeholder="Enter your pinterest"
              />
            </div>
            <div class="input-group">
              <label for="youtube">Youtube</label>
              <input
                type="text"
                id="youtube"
                v-model="sitesettings.site_youtube"
                placeholder="Enter your youtube"
              />
            </div>
            <div class="input-group">
              <label for="whatsapp">Whatsapp</label>
              <input
                type="text"
                id="whatsapp"
                v-model="sitesettings.site_whatsapp"
                placeholder="Enter your whatsapp"
              />
            </div>
          </div>
          <div class="form-group group-1">
            <div class="input-group">
              <label for="address">Address</label>
              <input
                type="text"
                id="address"
                v-model="sitesettings.site_address"
                placeholder="Enter your address"
              />
            </div>
          </div>
          <div class="form-group">
            <button @click="updateSiteSettings">Update Site Settings</button>
          </div>
        </div>
        <div v-if="activeTab === 2" class="tab-content-1">
          <div class="flex-btn">
            <h4>Slides</h4>
            <button @click="startAdd = true" class="my-btn cw wd glow">
              Add Slide
            </button>
          </div>
          <div class="my-table">
            <table>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Added by</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in slides" :key="item.id">
                  <td>
                    <img :src="$upload(item.image)" alt="Location Image" />
                  </td>
                  <td>{{ item.created_by.name }}</td>
                  <td>
                    <button
                      @click="
                        selectedItem = item;
                        startEdit = true;
                      "
                      class="btn edit"
                    >
                      Edit
                    </button>
                    <button
                      @click="
                        selectedItem = item;
                        startDelete = true;
                      "
                      class="btn delete"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="activeTab === 3" class="tab-content-1">
          <div class="flex-btn">
            <h4>Faqs</h4>
          </div>
          <br />
          <ul class="faqs">
            <li v-for="(faq, index) in sitesettings.site_faqs" :key="index">
              <div class="left">
                <div class="flex-btn">
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="3"
                    v-model="faq.question"
                    placeholder="Question"
                  ></textarea>
                </div>
                <div class="flex-btn">
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="3"
                    v-model="faq.answer"
                    placeholder="Answer"
                  >
                  </textarea>
                </div>
              </div>
              <div class="right">
                <button class="btn cancel" @click="deleteFaq(index)">
                  Delete
                </button>
              </div>
            </li>
          </ul>
          <div class="flex-btn faq-btns">
            <button @click="startaddfaq" class="my-btn cw wd glow info">
              Add Faq
            </button>
            <button @click="updateSiteSettings" class="my-btn cw wd glow">
              Save changes
            </button>
          </div>
        </div>
        <div v-if="activeTab === 4" class="tab-content-1">
          <div class="form-group">
            <div class="input-group">
              <label for="about">About</label>
              <QuillEditor
                :toolbar="'full'"
                v-model:content="sitesettings.site_about"
                :contentType="'html'"
                theme="snow"
              />
            </div>
          </div>
          <div class="form-group">
            <button @click="updateSiteSettings">Update About</button>
          </div>
        </div>
        <div v-if="activeTab === 5" class="tab-content-1">
          <div class="form-group">
            <div class="input-group">
              <label for="privacy">Privacy Policy</label>
              <QuillEditor
                :toolbar="minimal"
                v-model:content="sitesettings.privacy"
                :contentType="'html'"
                theme="snow"
              />
            </div>
          </div>
          <div class="form-group">
            <button @click="updateSiteSettings">Update Privacy Policy</button>
          </div>
        </div>
        <div v-if="activeTab === 6" class="tab-content-1">
          <div class="form-group">
            <div class="input-group">
              <label for="terms">Terms & Conditions</label>
              <QuillEditor
                :toolbar="minimal"
                v-model:content="sitesettings.terms"
                :contentType="'html'"
                theme="snow"
              />
            </div>
          </div>
          <div class="form-group">
            <button @click="updateSiteSettings">
              Update Terms & Conditions
            </button>
          </div>
        </div>
        <div v-if="activeTab === 7" class="tab-content-1">
          <div class="form-group">
            <label for="name">Name</label>
            <input
              type="text"
              id="name"
              v-model="user.name"
              placeholder="Enter your name"
            />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              type="email"
              id="email"
              v-model="user.email"
              placeholder="Enter your email"
            />
          </div>
          <div class="form-group">
            <button @click="updateProfile">Update Profile</button>
          </div>
        </div>
        <div v-if="activeTab === 8" class="tab-content-2">
          <div class="form-group">
            <label for="password">Current Password</label>
            <input
              type="password"
              id="password"
              v-model="user.cpassword"
              placeholder="Enter your current password"
            />
          </div>
          <div class="form-group">
            <label for="npassword">New Password</label>
            <input
              type="password"
              id="npassword"
              v-model="user.npassword"
              placeholder="Enter your new password"
            />
          </div>
          <div class="form-group">
            <label for="comfpassword">Confirm New Password</label>
            <input
              type="password"
              id="comfpassword"
              v-model="user.comfpassword"
              placeholder="Enter your confirm password"
            />
          </div>
          <div class="form-group">
            <button @click="updateProfilePassword">Update Password</button>
          </div>
        </div>
      </div>
    </div>

    <Model
      v-if="startAdd"
      @closeModel="startAdd = false"
      :modelTitle="'Add Slide'"
      :size="'sm'"
    >
      <div class="model-body">
        <div class="form-group">
          <label for="image">Slide Image</label>
          <o-upload
            :multiple="false"
            @imagesUploaded="
              (data) => {
                newSlide.image = data;
              }
            "
            @imagesUploadedFailed="
              (data) => {
                console.log('failed');
              }
            "
            :value="newSlide.image"
            @startLoader="() => $startLoader()"
            @stopLoader="() => $stopLoader()"
          />
        </div>
        <br />
        <div class="flex-btn">
          <button @click="startAdd = false" class="my-btn cw wd cancel">
            Cancel
          </button>
          <button @click="createSlide" class="my-btn cw glow wd">Add</button>
        </div>
      </div>
    </Model>
    <Model
      v-if="startEdit"
      @closeModel="startEdit = false"
      :modelTitle="'Edit Slide'"
      :size="'sm'"
    >
      <div class="model-body">
        <div class="form-group">
          <label for="image">Slide Image</label>
          <o-upload
            :multiple="false"
            @imagesUploaded="
              (data) => {
                selectedItem.image = data;
              }
            "
            @imagesUploadedFailed="
              (data) => {
                console.log('failed');
              }
            "
            :value="selectedItem.image"
            @startLoader="() => $startLoader()"
            @stopLoader="() => $stopLoader()"
          />
        </div>
        <br />
        <div class="flex-btn">
          <button @click="startEdit = false" class="my-btn cw wd cancel">
            Cancel
          </button>
          <button @click="updateSlide" class="my-btn cw glow wd">Update</button>
        </div>
      </div>
    </Model>
    <confirmModel
      v-if="startDelete"
      @closeModel="startDelete = false"
      :modelTitle="'Delete Slide'"
      :size="'sm'"
      :displayText="'Are you sure you want to delete this slide?'"
      @confirmModel="deleteSlide"
    />
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      user: {
        name: "",
        email: "",
        cpassword: "",
        npassword: "",
        comfpassword: "",
      },
      activeTab: 1,
      sitesettings: {
        site_name: "",
        site_logo: "",
        site_email: "",
        site_phone: "",
        site_address: "",
        site_facebook: "",
        site_twitter: "",
        site_instagram: "",
        site_linkedin: "",
        site_pinterest: "",
        site_youtube: "",
        site_whatsapp: "",
        site_tiktok: "",
        site_map: "",
        site_about: "",
        site_faqs: [],
        privacy: "",
        terms: "",
      },
      slides: [],
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      newSlide: {
        image: "",
      },
      newFaq: {
        question: "",
        answer: "",
      },
    };
  },
  methods: {
    updateProfile() {
      if (this.user.name && this.user.email) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_ADMIN_PROFILE", {
            data: this.user,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateProfilePassword() {
      if (
        this.user.cpassword &&
        this.user.npassword &&
        this.user.comfpassword
      ) {
        if (this.user.npassword === this.user.comfpassword) {
          this.$startLoader();
          this.$store
            .dispatch("UPDATE_ADMIN_PROFILE_PASSWORD", {
              data: this.user,
              token: this.$getAdminToken(),
            })
            .then((res) => {
              if (res) {
                if (res.status === 200) {
                  toast.success(res.data.message);
                  this.startEdit = false;
                  this.$stopLoader();
                } else {
                  toast.error(res.data.message);
                  this.$stopLoader();
                }
              }
            })
            .catch(() => {
              toast.error("Something went wrong, please try again later.");
              this.$stopLoader();
            });
        } else {
          toast.error("New password and confirm password should be same");
        }
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateSiteSettings() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_SETTINGS", {
          data: this.sitesettings,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.$stopLoader();
              this.getSettings();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getSlides() {
      this.$store.dispatch("GET_SLIDES").then((res) => {
        if (res) {
          if (res.status === 200) {
            this.slides = res.data.slides;
            this.newSlide = {
              image: "",
            };
          }
        }
      });
    },
    createSlide() {
      if (this.newSlide.image) {
        this.$startLoader();
        this.$store
          .dispatch("CREATE_SLIDE", {
            data: this.newSlide,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.startAdd = false;
                this.getSlides();
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateSlide() {
      if (this.selectedItem.image) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_SLIDE", {
            data: this.selectedItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.startEdit = false;
                this.getSlides();
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteSlide() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_SLIDE", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.startDelete = false;
              this.getSlides();
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getSettings() {
      this.$store.dispatch("GET_SETTINGS").then((res) => {
        if (res) {
          if (res.status === 200) {
            this.sitesettings = res.data.settings;
          }
        }
      });
    },
    deleteFaq(index) {
      this.sitesettings.site_faqs.splice(index, 1);
    },
    startaddfaq() {
      let newFaq = {
        question: "",
        answer: "",
      };
      this.sitesettings.site_faqs.push(newFaq);
    },
  },
  mounted() {
    document.title = "Settings";
    this.user = this.$getUser();
    this.getSlides();
    this.getSettings();
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
  .dash-content {
    padding: 2rem;
    background-color: white;
    border-radius: 10px;
    .tabs {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      column-gap: 1rem;
      .tab {
        padding: 1rem 2rem;
        cursor: pointer;
        font-weight: 600;
        position: relative;
        &.active {
          background-color: #eee;
          color: $primary_color;
          // after tooltip arrow bottom
          &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #eee;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        &:hover {
          background-color: #eee;
          color: $primary_color;
        }
      }
    }
    .tab-content {
      h4 {
        margin-bottom: 2rem;
      }
      .tab-content-1 {
        .form-group {
          margin-bottom: 20px;
          gap: 1rem;
          label {
            font-weight: 600;
            margin-bottom: 5px;
            display: block;
          }
          input {
            width: 100%;
            padding: 10px;
            border-radius: 10px;
            border: 1px solid #e5e8ee;
            outline: none;
          }
          button {
            padding: 1rem 2rem;
            border: none;
            border-radius: 10px;
            background-color: $primary_color;
            color: white;
            cursor: pointer;
            font-weight: 600;
          }
        }
      }
      .tab-content-2 {
        .form-group {
          margin-bottom: 20px;
          label {
            font-weight: 600;
            margin-bottom: 5px;
            display: block;
          }
          input {
            width: 400px;
            padding: 10px;
            border-radius: 10px;
            border: 1px solid #e5e8ee;
            outline: none;
          }
          button {
            padding: 1rem 2rem;
            border: none;
            border-radius: 10px;
            background-color: $primary_color;
            color: white;
            cursor: pointer;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.faqs {
  list-style: none;
  li {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    display: grid;
    grid-template-columns: 6fr 1fr;
    border-bottom: 1px solid #a5a5a5;
    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        padding: 1rem 2rem;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-weight: 600;
      }
    }
    .flex-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      textarea {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid #e5e8ee;
        outline: none;
      }
      button {
        padding: 1rem 2rem;
        border: none;
        border-radius: 10px;
        background-color: $primary_color;
        color: white;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
.faq-btns {
  button {
    width: 200px;
  }
}
</style>