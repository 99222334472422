import { createStore } from 'vuex'
import { UPLOAD_URL } from './variables.js';
import admins from './modules/admin.js';
import locations from './modules/locations.js';
import files from './modules/files.js';
import properties from './modules/properties.js';
import clientrequests from './modules/clientrequests.js';
import slides from './modules/slides.js';
import settings from './modules/settings.js';
import currency from './modules/currency.js';
import contacts from './modules/contacts.js';

export default createStore({
  state: {
    startLoader: false,
    showSANav: true,
    activePage: null,
    apiUploadUrl: UPLOAD_URL,
    sitesettings: null,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    admins,
    locations,
    files,
    properties,
    clientrequests,
    slides,
    settings,
    currency,
    contacts,
  }
})
