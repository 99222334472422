<template>
  <miLoader v-if="$store.state.startLoader" />
  <notifications />
  <router-view v-if="this.$store.state.sitesettings" />
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    getSettings() {
      this.$store.dispatch("GET_SETTINGS").then((res) => {
        if (res) {
          if (res.status === 200) {
            this.$store.state.sitesettings = res.data.settings;
          }
        }
      });
    },
    getCurrencies() {
      this.$store.dispatch("GET_CURRENCIES").then((res) => {
        if (res) {
          if (res.status === 200) {
            this.$store.state.currencies = res.data.currencies;
          }
        }
      });
    },
  },
  mounted() {
    this.getSettings();
    this.getCurrencies();
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --vsc-bg-button: #25d366 !important;
  --vsc-bg-footer: #eee !important;
  --vsc-bg-header: #25d366 !important;
  --vsc-text-color-header: #fff !important;
  --vsc-text-color-footer: #0d7e37 !important;
  --vsc-text-color-button: #fff !important;
  --vsc-outline-color: #25d366 !important;
  --vsc-border-color-bottom-header: #fff !important;
  --vsc-border-color-top-footer: #fff !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: #000;
}

$primary_color: #1d2088;
$secondary_color: #1eb9ee;
#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-100 {
  margin-top: 100px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-100 {
  margin-bottom: 100px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-100 {
  padding-bottom: 100px;
}
.bp {
  background: $primary_color;
}
.cp {
  color: $primary_color;
}
.bw {
  background: #fff;
}
.cw {
  color: #fff;
}
.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  font-weight: 500;
  i {
    margin-left: 5px;
  }
  &:hover {
    // transform: scale(1.1);
  }
}
.input-group {
  gap: 20px;
}
.group-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.group-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.group-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.group-5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.why-choose-us {
  margin-top: 50px;
  .header {
    font-size: 2rem;
    margin-bottom: 20px;
    @media (max-width: 750px) {
      font-size: 1.5rem;
    }
  }
  .sub-header {
    font-size: 1.25rem;
    margin-bottom: 20px;
    @media (max-width: 750px) {
      font-size: 1.15rem;
    }
  }
  .features {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @media (max-width: 750px) {
      grid-template-columns: 1fr;
    }
    .feature-item {
      border: 1px solid #e0e0e070;
      border-radius: 5px;
      overflow: hidden;
      display: flex;
      box-shadow: 0px 0px 10px 0px #00000013;
      .left {
        display: flex;
        align-items: center;
        padding: 20px 2rem;
        background-color: $primary_color;
        color: #fff;
        position: relative;
        // width: 250px;
        &:before {
          // triangle
          content: "";
          position: absolute;
          top: 10px;
          right: -9px;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 10px solid $primary_color;
        }
        i {
          font-size: 5rem;
          margin-right: 20px;
          position: absolute;
          bottom: -10px;
          left: -10px;
          opacity: 0.1;
        }
        .text {
          p {
            font-size: 1rem;
            font-weight: 400;
            opacity: 0.9;
          }
          h3 {
            font-size: 3rem;
            font-weight: 500;
            line-height: 1;
          }
        }
      }
      .right {
        padding: 20px;
        h3 {
          font-size: 1.25rem;
          margin-bottom: 10px;
          @media (max-width: 750px) {
            font-size: 1.15rem;
          }
        }
        p {
          font-size: 1rem;
          @media (max-width: 750px) {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
.newsletters {
  background-color: $primary_color;
  padding: 50px 0;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    h2 {
      font-size: 2rem;
      color: #fff;
      margin-bottom: 20px;
      @media (max-width: 750px) {
        font-size: 1.5rem;
      }
    }
    p {
      color: #fff;
      margin-bottom: 20px;
    }
    form {
      display: flex;
      align-items: center;
      input {
        padding: 0px 20px;
        outline: none;
        margin-right: 20px;
        width: 400px;
        font-size: 1rem;
        font-weight: 500;
        background: #fff;
        border-radius: 500px;
        z-index: 1;
        height: 60px;
        box-shadow: -10px 10px 10px 0px #0000001f;
        @media (max-width: 750px) {
          width: 60vw;
        }
      }
      button {
        padding: 0px 20px;
        background-color: darken($color: $secondary_color, $amount: 10%);
        color: #fff;
        border: none;
        border-radius: 500px;
        transition: all 0.3s;
        font-size: 1rem;
        font-weight: 500;
        margin-left: -70px;
        padding-left: 65px;
        height: 60px;
        text-transform: uppercase;
        font-weight: 600;
        box-shadow: 10px 10px 10px 0px #0000001f;
        &:hover {
          background-color: $secondary_color;
        }
      }
    }
  }
}

.my-btn {
  background: $primary_color;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  font-size: 14px;
  &.wd {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  &.glow {
    box-shadow: 0px 0px 15px 0px #1d21887e;
  }
  &:hover {
    background: darken($color: $primary_color, $amount: 10%);
  }
  &.bw {
    background: #fff;
    color: $primary_color;
    &:hover {
      background: #f0f0f0;
    }
  }
}

.filtered-area {
  margin-top: 50px;
  .category-list {
    .tab-header {
      display: flex;
      justify-content: space-between;
      font-size: 2rem;
      @media (max-width: 1500px) {
        font-size: 1.5rem;
      }
      @media (max-width: 1200px) {
        font-size: 1.25rem;
      }
      @media (max-width: 1000px) {
        font-size: 1.15rem;
      }
      @media (max-width: 750px) {
        font-size: 1.05rem;
      }
      @media (max-width: 500px) {
        font-size: 1rem;
      }
      .right {
        list-style: none;
        display: flex;
        column-gap: 30px;
        label {
          font-size: 1.2rem;
          color: #000;
          font-weight: 600;
          &:hover {
            cursor: pointer;
            color: $primary_color;
          }
          &.active {
            color: $primary_color;
            font-weight: 800;
          }
        }
      }
    }
  }
  .items-listing {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    grid-column-gap: 2rem !important;
    @media (max-width: 1700px) {
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }
    @media (max-width: 1500px) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    @media (max-width: 750px) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    @media (max-width: 500px) {
      grid-template-columns: 1fr;
    }
  }
}

.container {
  padding: 0 15rem;
  @media (max-width: 1500px) {
    padding: 0 8rem;
  }
  @media (max-width: 1200px) {
    padding: 0 4rem;
  }
  @media (max-width: 1000px) {
    padding: 0 3rem;
  }
  @media (max-width: 750px) {
    padding: 0 2rem;
  }
  @media (max-width: 500px) {
    padding: 0 1rem;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px;
  button {
    label {
      padding: 5px 15px;
      border: 1px solid #eeeeee;
      border-radius: 5px;
      margin: 0 10px;
      transition: all 0.3s;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        background-color: $primary_color;
        color: #fff;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      &.active {
        background-color: $primary_color;
        color: #fff;
      }
    }
  }
}
.form-group {
  .form-control {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    width: 100%;
    margin-bottom: 20px;
    outline: none;
  }
  label {
    font-size: 0.8rem;
    margin-bottom: 10px;
    display: inline-block;
  }
}
.info {
  background: $secondary_color;
  color: #fff;
}
.my-table {
  width: 100%;
  overflow-x: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      th {
        text-align: left;
        padding: 1rem 0;
        font-weight: 600;
        border-bottom: 1px solid #e5e8ee;
        p {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .sorts {
            display: flex;
            align-items: center;
            margin: 0 10px;
            i {
              margin-left: 3px;
              font-size: 0.8rem;
              color: #777;
              cursor: pointer;
              &:hover,
              &.active {
                color: #000;
              }
            }
          }
        }
        @media (max-width: 1500px) {
          font-size: 0.75rem;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 5px;
          border-bottom: 1px solid #e5e8ee;
          @media (max-width: 1500px) {
            font-size: 0.75rem;
          }
          img {
            width: 100px;
            height: 50px;
            object-fit: cover;
            border-radius: 5px;
          }
          button {
            margin-right: 10px;
            display: inline-block;
            @media (max-width: 1500px) {
              font-size: 0.75rem;
              padding: 3px 12px;
            }
            &.edit {
              background-color: #1d2088;
              color: white;
              @media (max-width: 1500px) {
                font-size: 0.75rem;
                padding: 3px 12px;
              }
              &:hover {
                background-color: #1eb9ee;
              }
            }
            &.delete {
              background-color: #a82929;
              color: white;
              &:hover {
                background-color: #7a1f1f;
              }
            }
            &.default {
              background-color: #1d2088;
              color: white;
              &:hover {
                background-color: #1eb9ee;
              }
            }
            @media (max-width: 800px) {
              font-size: 0.9rem;
              padding: 5px 10px;
            }
            @media (max-width: 500px) {
              font-size: 0.8rem;
              padding: 5px 10px;
            }
          }
          @media (max-width: 800px) {
            font-size: 0.9rem;
          }
          @media (max-width: 500px) {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
.flex-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    margin-right: 10px;
  }
}

.cancel {
  background-color: #a82929;
  color: white;
  &:hover {
    background-color: #7a1f1f;
  }
}

h2.my-header {
  font-size: 2rem;
  @media (max-width: 1500px) {
    font-size: 1.5rem;
  }
  @media (max-width: 1200px) {
    font-size: 1.25rem;
  }
  @media (max-width: 1000px) {
    font-size: 1.15rem;
  }
  @media (max-width: 750px) {
    font-size: 1.05rem;
  }
  @media (max-width: 500px) {
    font-size: 1rem;
  }
}

.container {
  .faqs {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    background: #fff;
    padding-bottom: 20px;
    @media (max-width: 800px) {
      margin-top: 30px;
    }
    .header {
      font-size: 1.9rem;
      text-align: center;
      margin-top: 1.5rem;
      @media (max-width: 800px) {
        font-size: 1.5rem;
        margin-top: 0;
      }
    }
    .faq {
      width: 100%;
      padding: 20px;
      border-bottom: 1px solid #e8ecfd;
      margin-bottom: 10px;
      background: #e8ecfd;
      cursor: pointer;
      @media (max-width: 800px) {
        padding: 10px;
      }
      .question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        h3 {
          font-size: 1.1rem;
          @media (max-width: 800px) {
            font-size: 1rem;
          }
          @media (max-width: 500px) {
            font-size: 0.8rem;
          }
        }
        i {
          border: 1px solid #000;
          font-size: 0.9rem;
          padding: 5px;
          border-radius: 50%;
          @media (max-width: 800px) {
            font-size: 0.8rem;
          }
          @media (max-width: 500px) {
            font-size: 0.6rem;
          }
        }
      }
      .answer {
        height: 0;
        overflow: hidden;
        padding: 0 20px;
        transition: all 0.3s;
        p {
          font-size: 0.9rem;
          color: #333;
          @media (max-width: 800px) {
            font-size: 0.7rem;
          }
        }
      }
      &.active {
        .answer {
          border-top: 1px solid #37374d2d;
          padding: 20px;
          margin-top: 20px;
          height: auto;
        }
      }
    }
  }
}
.form-search {
  width: 200px;
  display: flex;
  align-items: flex-start;
  input {
    width: 100%;
    font-size: 0.9rem;
    padding: 5px 10px;
    border: 1px solid #e0e0e0;
    outline: none;
    border-radius: 5px;
    font-weight: 400;
  }
}
</style>
