<template>
  <div class="Changecurrency">
    <div class="currencyauges" @click="toggle = true">
      <div class="currency">
        <span>
          {{ currency }}
        </span>
      </div>
    </div>
    <div class="currencyuage-toggle" v-if="toggle">
      <div
        class="currency"
        @click="choose(currency.name)"
        v-for="currency in $store.state.currencies"
        :key="currency.currency_id"
      >
        <span>
          {{ currency.name }}
        </span>
      </div>
    </div>
    <!-- fa carret-down -->
    <i class="fas fa-caret-down"></i>
  </div>
</template>

<script>
export default {
  name: "Changecurrency",
  data() {
    return {
      currency: "RWF",
      toggle: false,
    };
  },
  props: {
    showDrop: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    choose(currency) {
      this.currency = currency;
      this.toggle = false;
      this.changecurrency(currency);
    },
    changecurrency(currency) {
      localStorage.setItem("kcurrency", currency);
      window.location.reload();
    },
  },
  mounted() {
    this.currency = localStorage.getItem("kcurrency");
    if (!this.currency) {
      this.currency = "RWF";
      localStorage.setItem("kcurrency", this.currency);
    }
  },
  computed: {},
  // watch showDrop
  watch: {
    showDrop: function (val) {
      if (this.showDrop == false) {
        this.toggle = false;
      } else {
        this.toggle = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.Changecurrency {
  display: flex;
  align-items: center;
  position: relative;
}

.currencyauges {
  span {
    color: #fff;
  }
}

.currency {
  display: flex;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}

.currencyuage-toggle {
  position: absolute;
  top: 30px;
  right: 0;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: max-content;
  z-index: 999;
  color: #000;
  .currency {
    margin: 0;
  }
}

i {
  color: #fff;
}

@media screen and (max-width: 576px) {
  .currencyauges {
    // display: none;
  }

  .Changecurrency {
    // flex-direction: column;
  }
}
</style>

