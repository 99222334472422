<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">ANALYTICS</h1>
      <div class="dash-content">
        <iframe
          style="width: 100%; height: 72vh; border: 0"
          height="2125"
          src="https://lookerstudio.google.com/embed/reporting/a8613bdc-0bf9-43c7-9815-41f7a1c46b98/page/kIV1C"
          frameborder="0"
          allowfullscreen
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {
    document.title = "Dashboard";
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .dash-content {
    padding: 1rem;
  }
}
</style>