import { BACKEND } from '../variables.js';
import axios from 'axios'
const GET_SLIDES = BACKEND + '/slides/getAllSlides';
const CREATE_SLIDE = BACKEND + '/slides/createSlide';
const UPDATE_SLIDE = BACKEND + '/slides/updateSlide';
const DELETE_SLIDE = BACKEND + '/slides/deleteSlide';

export default {
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        ['GET_SLIDES'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_SLIDES,
            })
        },
        ['CREATE_SLIDE'](state, payload) {
            return axios({
                method: 'POST',
                url: CREATE_SLIDE,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['UPDATE_SLIDE'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_SLIDE,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['DELETE_SLIDE'](state, payload) {
            return axios({
                method: 'POST',
                url: DELETE_SLIDE,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        }

    },
};
