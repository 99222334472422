<template>
  <div class="About">
    <NavBar />
    <div class="jumbotron container">
      <div class="left">
        <h1>About Us</h1>
        <p v-html="$store.state.sitesettings.site_about"></p>
      </div>
    </div>
    <div class="about-section container">
      <div class="why-choose-us">
        <img :src="$img('site.png')" alt="" />
        <div class="why-details">
          <h3>Why you should choose us.</h3>
          <p>
            Creating quality urban lifestyles, building stronger communities
          </p>
          <div class="icons">
            <p>
              <i class="fa-regular fa-square-check"></i>
              <span>World class</span>
            </p>
            <p>
              <i class="fa-regular fa-square-check"></i>
              <span>Affordable</span>
            </p>
            <p>
              <i class="fa-regular fa-square-check"></i>
              <span>Trusted</span>
            </p>
            <p>
              <i class="fa-regular fa-square-check"></i>
              <span>Amenities</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="faqs">
        <h2 class="header">Frequently Asked Questions</h2>
        <div class="faqs">
          <div
            class="faq"
            v-for="(faq, index) in $store.state.sitesettings.site_faqs"
            :key="index"
            :class="{ active: faq.active }"
            @click="faq.active = !faq.active"
          >
            <div class="question">
              <h3>{{ faq.question }}</h3>
              <i class="fas fa-chevron-down"></i>
            </div>
            <div class="answer">
              <p>{{ faq.answer }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="newsletters">
      <div class="container">
        <h2>Spend less, get more with K-Realty</h2>
        <p>
          Subscribe to our newsletter and get the best deals on your favorite
          destinations.
        </p>
        <form action="">
          <input type="email" placeholder="Enter your email" />
          <button class="primary">Subscribe</button>
        </form>
      </div>
    </div> -->
    <div class="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d255203.55263329137!2d30.12724445!3d-1.9297706000000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dca4258ed8e797%3A0xf32b36a5411d0bc8!2sKigali!5e0!3m2!1sen!2srw!4v1708441285862!5m2!1sen!2srw"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <Footer />
  </div>
</template>

<script>
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
$primary_color: #0c3b7c;
$secondary_color: #00bafc;
.About {
  .jumbotron {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    background-attachment: fixed;
    background-size: cover;
    background: #fafbff;
    flex-wrap: wrap;
    @media (max-width: 800px) {
      margin-top: 60px;
    }
    .left {
      width: 100%;
      padding-top: 2rem;
      height: 400px;
      h1 {
        font-size: 1.9rem;
        margin-bottom: 1.5rem;
        text-align: center;
        margin-top: 1.5rem;
      }
      p {
        font-size: 1.15rem;
        color: #000;
        margin-top: 1rem;
        font-weight: 400;
        display: block;
        @media (max-width: 800px) {
          font-size: 0.9rem;
          font-weight: 400;
        }
      }
    }
  }
  .about-section {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 800px) {
      margin-top: 60px;
      flex-direction: column;
      align-items: flex-start;
    }
    .why-choose-us {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
      }
      @media (max-width: 500px) {
        margin-top: 100px;
      }
      img {
        width: 50%;
        height: 400px;
        margin-right: 1rem;
        object-fit: cover;
        @media (max-width: 800px) {
          width: 100%;
          height: 300px;
          margin-right: 0;
        }
      }
      .why-details {
        width: 50%;
        @media (max-width: 800px) {
          width: 100%;
          padding: 0;
          margin-top: 20px;
        }
        h3 {
          font-size: 1.9rem;
          margin-bottom: 1.5rem;
          text-align: left;
          margin-top: 1.5rem;
          @media (max-width: 800px) {
            font-size: 1.5rem;
          }
        }
        p {
          font-size: 1rem;
          color: #000;
          margin-top: 1rem;
          font-weight: 500;
          @media (max-width: 800px) {
            font-size: 0.9rem;
            font-weight: 400;
          }
        }
        .icons {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          @media screen and (max-width: 500px) {
            grid-template-columns: repeat(1, 1fr);
          }
          p {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            i {
              margin-right: 5px;
              font-size: 1.5rem;
              color: $secondary_color;
              @media (max-width: 800px) {
                font-size: 1.2rem;
              }
            }
            span {
              font-size: 1.2rem;
              @media (max-width: 800px) {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
  .map {
    width: 100%;
    iframe {
      width: 100%;
      border: 0;
    }
  }
}
</style>
