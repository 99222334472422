import { BACKEND } from '../variables.js';
import axios from 'axios'
const GET_CURRENCIES = BACKEND + '/currency/getAllCurrency';

export default {
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        ['GET_CURRENCIES'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_CURRENCIES,
            })
        },
    },
};
