import { BACKEND } from '../variables.js';
import axios from 'axios'
const GET_REQUESTS = BACKEND + '/requests/getAllRequests';
const CREATE_REQUEST = BACKEND + '/requests/createRequest';
const UPDATE_REQUEST = BACKEND + '/requests/updateRequest';
const DELETE_REQUEST = BACKEND + '/requests/deleteRequest';

export default {
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        ['GET_REQUESTS'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_REQUESTS + `?page=${payload.data.page}`,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['CREATE_REQUEST'](state, payload) {
            return axios({
                method: 'POST',
                url: CREATE_REQUEST,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['UPDATE_REQUEST'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_REQUEST,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['DELETE_REQUEST'](state, payload) {
            return axios({
                method: 'POST',
                url: DELETE_REQUEST,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        }

    },
};
