import { BACKEND } from '../variables.js';
import axios from 'axios'
const GET_CONTACTS = BACKEND + '/contact/getAllContacts';
const CREATE_CONTACT = BACKEND + '/contact/createContact';
const UPDATE_CONTACT = BACKEND + '/contact/updateContact';
const DELETE_CONTACT = BACKEND + '/contact/deleteContact';

export default {
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        ['GET_CONTACTS'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_CONTACTS + `?page=${payload.data.page}`,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['CREATE_CONTACT'](state, payload) {
            return axios({
                method: 'POST',
                url: CREATE_CONTACT,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['UPDATE_CONTACT'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_CONTACT,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['DELETE_CONTACT'](state, payload) {
            return axios({
                method: 'POST',
                url: DELETE_CONTACT,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        }

    },
};
