<template>
  <div class="MySelect">
    <div class="custom-select" :tabindex="tabindex" @blur="open = false">
      <div class="selected" :class="{ open: open }" @click="open = !open">
        <i
          v-if="icon && selected"
          :class="selected[iconKey]"
          :style="'color:' + iconColor"
        ></i>
        <span :style="'color:' + color">
          {{ selectedValue }}
        </span>
      </div>
      <div
        class="items"
        :class="{ selectHide: !open }"
        :style="'color:' + color"
        v-if="options && options.length"
      >
        <div
          v-for="(option, index) in options"
          :key="index"
          @click="select(option)"
        >
          <i
            v-if="icon"
            :class="option[iconKey]"
            :style="'color:' + iconColor"
          ></i>
          {{ objects ? option[labelKey] : option }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MySelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    icon: { Boolean, required: false, default: false },
    objects: { Boolean, required: false, default: false },
    labelKey: { String, required: false, default: "" },
    valueKey: { String, required: false, default: "" },
    color: { String, required: false, default: "#000" },
    iconKey: { String, required: false, default: "" },
    iconColor: { String, required: false, default: "#000" },
    value: { required: false },
  },
  data() {
    return {
      open: false,
      selected: this.options[0],
    };
  },
  methods: {
    select(option) {
      this.selected = option;
      this.open = false;
      this.$emit("input", this.objects ? option[this.valueKey] : option);
    },
  },
  computed: {
    selectedValue() {
      return this.objects ? this.selected[this.labelKey] : this.selected;
    },
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.selected = this.options.find(
            (option) => option[this.valueKey] === value || option === value
          );
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  line-height: 2.5;
}

.custom-select .selected {
  background-color: transparent;
  border-radius: 6px;
  border: none;
  color: #000;
  cursor: pointer;
  user-select: none;
  padding-left: 10px;
  span {
    margin-left: 5px;
  }
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 50%;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #000 transparent transparent transparent;
  transform: translateY(-20%);
}

.custom-select .items {
  border-radius: 6px;
  overflow: hidden;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  z-index: 99;
}

.custom-select .items div {
  color: #000;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #eee;
}

.selectHide {
  display: none;
}
</style>