<template>
  <router-link
    class="listing-item"
    :to="{
      name: 'ViewProperty',
      params: { id: item.property_id, name: $urlCompatible(item.title) },
    }"
  >
    <div class="image">
      <img :src="$upload(item.image)" :alt="item.title" />
      <p class="tag" :class="item.type">
        {{ item.type }}
      </p>
    </div>
    <div class="details">
      <h3 class="price">
        {{ $comma(item.price) }}
        <span>{{ item.currency }}</span>
      </h3>
      <h3>{{ item.title }}</h3>
      <p>
        <i class="fa-solid fa-location-dot"></i>
        <span>{{ item.location.name }}</span>
      </p>
      <div class="group-3" v-if="item.category != 'Land'">
        <p>
          <!-- beds -->
          <i class="fa-solid fa-bed"></i>
          <label for="">beds:</label>
          <span>&nbsp;{{ item.features.bedrooms }}</span>
        </p>
        <!-- baths -->
        <p>
          <i class="fa-solid fa-bath"></i>
          <label for="">baths:</label>
          <span
            >&nbsp;
            {{ item.features.bathrooms }}
          </span>
        </p>
        <!-- parking -->
        <p>
          <i class="fa-solid fa-car"></i>
          <label for="">parking:</label>
          <span
            >&nbsp;
            {{ item.features.parking }}
          </span>
        </p>
      </div>
      <div class="group-3" v-else>
        <p>
          <i class="mdi mdi-land-plots-marker"></i>
          <label for="">size:</label>
          <span>
            &nbsp;
            {{ item.size }}
          </span>
        </p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
.listing-item {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 0px;
  border: none;
  .image {
    position: relative;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
    @media (max-width: 1500px) {
      height: 200px;
    }
    @media (max-width: 1200px) {
      height: 180px;
    }
    @media (max-width: 1000px) {
      height: 160px;
    }
    @media (max-width: 750px) {
      height: 200px;
    }
    @media (max-width: 500px) {
      height: 220px;
    }
    img {
      width: 100%;
    }
    .tag {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: $secondary_color;
      color: #fff;
      padding: 3px 15px;
      border-radius: 50px;
      font-size: 0.9rem;
      font-weight: 500;
      &.Sale {
        background-color: #3ec55c;
      }
      &.Sold {
        background-color: #b9291f;
      }
      &.Occupied {
        background-color: #ac6508;
      }
      @media (max-width: 1500px) {
      }
      @media (max-width: 1200px) {
      }
      @media (max-width: 1000px) {
      }
      @media (max-width: 750px) {
      }
      @media (max-width: 500px) {
      }
    }
  }
  .details {
    padding: 20px;
    border: none;
    border-radius: 10px;
    background: var(--white, #fff);
    box-shadow: 0px 0px 15px 0px #d7dbeb;
    margin-top: -4px;
    h3 {
      font-size: 1.25rem;
      margin-bottom: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $primary_color;
      font-weight: 500;
      &.price {
        &::after {
          content: "";
          width: 80%;
          background: $primary_color;
          height: 2px;
          width: 60px;
          display: block;
        }
      }
      @media (max-width: 1500px) {
        font-size: 1.1rem;
      }
      @media (max-width: 1200px) {
        font-size: 1rem;
      }
      @media (max-width: 1000px) {
      }
      @media (max-width: 750px) {
      }
      @media (max-width: 500px) {
      }
    }
    p {
      display: flex;
      align-items: center;
      font-size: 0.6rem;
      margin-bottom: 10px;
      @media (max-width: 1500px) {
        font-size: 0.6rem;
      }
      @media (max-width: 1200px) {
        font-size: 0.6rem;
      }
      @media (max-width: 1000px) {
      }
      @media (max-width: 750px) {
      }
      @media (max-width: 500px) {
      }

      i {
        margin-right: 5px;
        font-size: 0.9rem;
        @media (max-width: 1500px) {
          font-size: 0.8rem;
        }
        @media (max-width: 1200px) {
          font-size: 0.8rem;
        }
        @media (max-width: 1000px) {
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
      }
      span,
      label {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.75rem;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      border-top: 1px solid #e0e0e0;
      padding-top: 10px;
      @media (max-width: 1500px) {
        margin-top: 10px;
      }
      @media (max-width: 1200px) {
      }
      @media (max-width: 1000px) {
      }
      @media (max-width: 750px) {
      }
      @media (max-width: 500px) {
      }
      span {
        display: flex;
        align-items: center;
        font-size: 1rem;
        @media (max-width: 1500px) {
          font-size: 0.8rem;
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 1000px) {
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
        i {
          margin-right: 5px;
          color: $primary_color;
          @media (max-width: 1500px) {
            font-size: 0.8rem;
          }
          @media (max-width: 1200px) {
          }
          @media (max-width: 1000px) {
          }
          @media (max-width: 750px) {
          }
          @media (max-width: 500px) {
          }
        }
      }
      .btn {
        padding: 10px 20px;
        border: 1px solid $primary_color;
        color: $primary_color;
        text-decoration: none;
        border-radius: 5px;
        transition: all 0.3s;
        font-size: 1rem;
        font-weight: 500;
        @media (max-width: 1500px) {
          padding: 4px 8px;
          font-size: 0.8rem;
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 1000px) {
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
        &:hover {
          background-color: $primary_color;
          color: #fff;
        }
      }
    }
  }
}
.group-3 {
  display: flex;
  justify-content: space-between;
  p {
    display: flex;
    align-items: center;
    i {
      margin-right: 5px;
      font-size: 0.9rem;
    }
    span {
      font-size: 0.75rem;
    }
  }
}
</style>