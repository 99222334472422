<template>
  <div id="NavBar">
    <TopBar />
    <div class="nav-bar">
      <router-link class="left" :to="{ name: 'Home' }">
        <img src="/img/krealty.png" alt="Cumbinn Logo" />
      </router-link>
      <div class="middle">
        <!-- links for real estate web  -->
        <ul class="nav-links">
          <li>
            <router-link to="/" class="nav-link">Home</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Properties' }" class="nav-link"
              >Property Listings</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'About' }" class="nav-link"
              >About Us</router-link
            >
          </li>
        </ul>
      </div>
      <div class="right">
        <router-link to="/contact" class="nav-link contact"
          >Contact Us</router-link
        >
      </div>
    </div>
    <div class="small-nav-toggler">
      <img src="/img/krealty.png" alt="Cumbinn Logo" />
      <i class="fas fa-bars" @click="toggleNav()"></i>
    </div>
    <div class="small-nav" v-if="showSmallNav">
      <div class="nav-body">
        <div class="small-nav-header">
          <h2>Menu</h2>
          <i class="fas fa-times" @click="toggleNav()"></i>
        </div>
        <div class="nav-bar" :class="{ active: showsmallnavcontent }">
          <router-link class="left" :to="{ name: 'Home' }">
            <img src="/img/krealty.png" alt="Cumbinn Logo" />
          </router-link>
          <div class="right">
            <i class="fas fa-bars" @click="showSmallNav = !showSmallNav"></i>
          </div>
        </div>
        <div class="middle">
          <ul class="nav-links">
            <li>
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Properties' }" class="nav-link"
                >Property Listings</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'About' }" class="nav-link"
                >About Us</router-link
              >
            </li>
            <li>
              <router-link to="/contact" class="nav-link"
                >Contact Us</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <VueSocialChat icon :attendants="attendants">
      <template #header>
        <p>Click one of our representatives below to chat on WhatsApp.</p>
      </template>
      <template #button>
        <img
          src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
          alt="icon whatsapp"
          aria-hidden="true"
        />
      </template>
      <template #footer>
        <small>Opening hours: 8am to 6pm</small>
      </template>
    </VueSocialChat>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showSmallNav: false,
      showsmallnavcontent: false,
      attendants: [
        {
          app: "whatsapp",
          label: "Support",
          name: "K-Realty",
          number: "250794103795",
          avatar: {
            src: "https://krealty.s3.ap-northeast-2.amazonaws.com/uploads/Team/krealty.png",
            alt: "K-Realty avatar",
          },
        },
        // {
        //   app: "whatsapp",
        //   label: "Manager",
        //   name: "Muhire Eric",
        //   number: "250723660491",
        //   avatar: {
        //     src: "https://krealty.s3.ap-northeast-2.amazonaws.com/uploads/Team/eric.png",
        //     alt: "Muhire Eric avatar",
        //   },
        // },
        // {
        //   app: "whatsapp",
        //   label: "Agent",
        //   name: "Caissy",
        //   number: "250788880976",
        //   avatar: {
        //     src: "https://krealty.s3.ap-northeast-2.amazonaws.com/uploads/Team/caissy.png",
        //     alt: "Caissy avatar",
        //   },
        // },
        // {
        //   app: "whatsapp",
        //   label: "Agent",
        //   name: "Johnson",
        //   number: "250781525693",
        //   avatar: {
        //     src: "https://krealty.s3.ap-northeast-2.amazonaws.com/uploads/Team/johnson.jpg",
        //     alt: "Johnson avatar",
        //   },
        // },
      ],
    };
  },
  methods: {
    toggleNav() {
      this.showsmallnavcontent = !this.showsmallnavcontent;
      setTimeout(() => {
        this.showSmallNav = !this.showSmallNav;
      }, 500);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
#NavBar {
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  .nav-bar {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    justify-content: space-between;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px 10rem;
    @media (max-width: 1500px) {
      padding: 10px 5rem;
    }
    @media (max-width: 1200px) {
      padding: 7px 4rem;
    }
    @media (max-width: 1000px) {
      padding: 5px 3rem;
      display: none;
    }
    @media (max-width: 750px) {
      padding: 5px 2rem;
    }
    @media (max-width: 500px) {
      padding: 5px 1rem;
    }
    .middle,
    .right {
      .nav-links {
        display: flex;
        list-style: none;
        align-items: center;
        a {
          text-decoration: none;
          color: #000;
          margin: 0 10px;
          font-weight: 500;
          font-size: 18px;
          @media (max-width: 1500px) {
            font-size: 14px;
          }
          @media (max-width: 1200px) {
          }
          @media (max-width: 1000px) {
          }
          @media (max-width: 750px) {
          }
          @media (max-width: 500px) {
          }
          &:hover {
            color: $primary_color;
          }
        }
        .drop-item {
          position: relative;
          cursor: pointer;
          padding: 20px 16px;

          @media (max-width: 1500px) {
            padding: 10px 10px;
          }
          @media (max-width: 1200px) {
          }
          @media (max-width: 1000px) {
          }
          @media (max-width: 750px) {
          }
          @media (max-width: 500px) {
          }
          .drop-content {
            display: none;
            position: absolute;
            background-color: #fff;
            min-width: 160px;
            margin-top: 20px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            @media (max-width: 1500px) {
              margin-top: 10px;
            }
            @media (max-width: 1200px) {
            }
            @media (max-width: 1000px) {
            }
            @media (max-width: 750px) {
            }
            @media (max-width: 500px) {
            }
            .drop-link {
              color: $primary_color;
              padding: 12px 16px;
              text-decoration: none;
              display: block;
              @media (max-width: 1500px) {
                padding: 10px 10px;
                font-size: 12px;
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 1000px) {
              }
              @media (max-width: 750px) {
              }
              @media (max-width: 500px) {
              }
              &:hover {
                background-color: $primary_color;
                color: #fff;
                font-weight: 800;
              }
            }
          }
          &:hover .drop-content {
            display: block;
          }
        }
        .router-link-active,
        .router-link-exact-active {
          color: $primary_color;
          font-weight: 800;
        }
      }
    }
    .left {
      img {
        width: 150px;
        @media (max-width: 1500px) {
          width: 150px;
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 1000px) {
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
      }
    }
  }
  .small-nav-toggler {
    width: 100%;
    background: #eee;
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    @media (max-width: 1000px) {
      display: flex;
    }
    img {
      width: 100px;
      @media (max-width: 1500px) {
        width: 100px;
      }
      @media (max-width: 1200px) {
      }
      @media (max-width: 1000px) {
      }
      @media (max-width: 750px) {
      }
      @media (max-width: 500px) {
      }
    }
    i {
      font-size: 2rem;
      cursor: pointer;
      @media (max-width: 1500px) {
        font-size: 1.5rem;
      }
      @media (max-width: 1200px) {
      }
      @media (max-width: 1000px) {
      }
      @media (max-width: 750px) {
      }
      @media (max-width: 500px) {
      }
    }
  }
  .small-nav {
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.63);
    z-index: 100;
    top: 0;
    left: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    .nav-body {
      display: block;
      width: 250px;
      height: 100vh;
      z-index: 100;
      background-color: #fff;
      box-shadow: 4px 0px 30px 0px rgba(131, 98, 234, 0.05);
      .small-nav-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        background-color: #fff;
        color: #000;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-left: -300px;
        transition: all 0.5s ease;
        .fa-times {
          font-size: 1.5rem;
          cursor: pointer;
          border: 1px solid #0000007c;
          padding: 5px;
          border-radius: 5px;
          &:hover {
            background: #e2e2e2;
          }
        }
        &.active {
          margin-left: 0;
        }
      }
      .nav-bar {
        padding: 10px 20px;
        .left {
          img {
            width: 150px;
          }
        }
        .right {
          i {
            font-size: 1.5rem;
            cursor: pointer;
          }
        }
      }
      .middle {
        ul {
          padding: 0;
          .nav-link {
            text-decoration: none;
            color: #000;
            margin: 0 0px;
            font-weight: 500;
            font-size: 16px;
            padding: 10px;
            display: block;
            @media (max-width: 1500px) {
              font-size: 14px;
            }
            @media (max-width: 1200px) {
            }
            @media (max-width: 1000px) {
            }
            @media (max-width: 750px) {
            }
            @media (max-width: 500px) {
            }
            &:hover {
              color: $primary_color;
              background: #f0f0f0;
            }
          }
          .router-link-active,
          .router-link-exact-active {
            color: $primary_color;
            font-weight: 800;
            background: #e2e2e2;
          }
        }
      }
    }
  }
}
.contact {
  font-size: 18px !important;
  background: $primary_color;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  @media (max-width: 1500px) {
    font-size: 14px !important;
  }
  &:hover {
    background: $secondary_color;
  }
}
</style>