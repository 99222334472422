<template>
  <div class="home">
    <NavBar />
    <div class="container">
      <!-- Browse by Destination  -->
      <div class="location-area">
        <div class="title">
          <h2>Desired locations</h2>
          <div class="search-loc">
            <input
              type="text"
              placeholder="Search"
              @input="searchLocation()"
              v-model="searchQ"
            />
          </div>
        </div>
        <ul>
          <li v-for="location in fewlocations" :key="location.location_id">
            <router-link
              :to="{
                name: 'Properties',
                query: { location: location.location_id },
              }"
              class="img"
            >
              <img :src="$upload(location.image)" :alt="location.name" />
            </router-link>
            <router-link
              :to="{
                name: 'Properties',
                query: { location: location.location_id },
              }"
              class="details"
            >
              <p>
                {{
                  $num(
                    location.housesCount +
                      location.apartmentsCount +
                      location.landCount
                  )
                }}
              </p>
              <h3>
                <i class="fa fa-location-dot"></i>
                {{ location.name }}
              </h3>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="container">
      <div class="contact-section">
        <div class="contact-body">
          <div class="left">
            <img src="/img/hand.png" alt="" />
            <img src="/img/ell.png" alt="" class="ellipse" />
          </div>
          <div class="right">
            <div class="inner-left">
              <h2>Contact Us for Renting or Selling Your Property!</h2>
              <p>
                Landlords with properties for rent or sale: Register now to
                connect with the K-Realty team!
              </p>
            </div>
            <div class="inner-right">
              <router-link :to="{ name: 'Contact' }" class="my-btn bw wd"
                >Contact Us</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      fewlocations: [],
      alllocations: [],
      searchQ: "",
    };
  },
  methods: {
    getFewLocations() {
      this.$startLoader();
      this.$store
        .dispatch("GET_LOCATIONS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 100,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.alllocations = res.data.locations.data;
              this.fewlocations = res.data.locations.data;
            } else {
              toast.error(res.data.message);
            }
          }
          this.$stopLoader();
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    searchLocation() {
      if (this.searchQ) {
        this.fewlocations = this.alllocations.filter((location) =>
          location.name.toLowerCase().includes(this.searchQ.toLowerCase())
        );
      } else {
        this.fewlocations = this.alllocations;
      }
    },
  },
  mounted() {
    this.getFewLocations();
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
.home {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  .location-area {
    margin-top: 200px;
    margin-bottom: 50px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        margin-bottom: 20px;
        font-size: 2.5rem;
      }
      .right {
        button {
          text-decoration: none;
          border-radius: 5px;
          transition: all 0.3s;
          font-size: 1.35rem;
          font-weight: 500;
          color: $primary_color;
        }
      }
      .search-loc {
        input {
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          width: 100%;
          font-size: 1rem;
          font-weight: 500;
          color: #000;
          border: 1px #0000001a solid;
          outline: none;
        }
      }
    }
    ul {
      list-style: none;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      @media (max-width: 1500px) {
      }
      @media (max-width: 1280px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 1000px) {
        display: grid;
        grid-template-columns: 1fr;
      }
      @media (max-width: 750px) {
      }
      @media (max-width: 500px) {
      }
      li {
        border: none;
        border-radius: 20px;
        overflow: hidden;
        height: 300px;
        position: relative;
        cursor: pointer;
        .img {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .details {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.603) 0%,
            rgba(0, 0, 0, 0.699) 50%,
            rgba(0, 0, 0, 0) 100%
          );
          padding: 20px 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          h3 {
            font-size: 1.25rem;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            font-weight: 300;
            i {
              margin-right: 5px;
              font-size: 1rem;
              width: auto;
            }
          }
          p {
            font-size: 4rem;
            color: #ffffffa8;
            font-weight: 600;
          }
        }
      }
    }
    .view-more {
      margin-top: 20px;
      text-align: right;
      a {
        padding: 10px 20px;
        text-decoration: none;
        border-radius: 5px;
        transition: all 0.3s;
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }
}

.contact-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafbff;
  padding: 2rem 0;
  padding-bottom: 5rem;
  .contact-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    background: $primary_color;
    width: 100%;
    border-radius: 2rem;
    overflow: hidden;
    position: relative;
    @media (max-width: 1500px) {
    }
    @media (max-width: 1200px) {
    }
    @media (max-width: 1000px) {
      margin-top: 0px;
    }
    @media (max-width: 750px) {
    }
    @media (max-width: 500px) {
    }
    .left {
      width: 25%;
      @media (max-width: 1500px) {
      }
      @media (max-width: 1200px) {
      }
      @media (max-width: 1000px) {
        display: none;
      }
      @media (max-width: 750px) {
      }
      @media (max-width: 500px) {
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 2;
        position: relative;
      }
      .ellipse {
        position: absolute;
        bottom: 0;
        left: 18%;
        width: 100px;
        height: 100px;
        z-index: 1;
      }
    }
    .right {
      width: 70%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5rem 2rem;
      column-gap: 2rem;
      @media (max-width: 1500px) {
      }
      @media (max-width: 1200px) {
      }
      @media (max-width: 1000px) {
        width: 100%;
        padding: 2rem;
        flex-direction: column;
      }
      @media (max-width: 750px) {
      }
      @media (max-width: 500px) {
      }
      .inner-left {
        width: 60%;
        @media (max-width: 1500px) {
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 1000px) {
          width: 100%;
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
        h2 {
          font-size: 2rem;
          margin-bottom: 20px;
          color: #fff;
          font-weight: 600;
          @media (max-width: 1500px) {
          }
          @media (max-width: 1200px) {
            font-size: 1.5rem;
          }
          @media (max-width: 1000px) {
            font-size: 1.2rem;
          }
          @media (max-width: 750px) {
            font-size: 1rem;
          }
          @media (max-width: 500px) {
            font-size: 1rem;
          }
        }
        p {
          font-size: 1rem;
          color: #fff;
          @media (max-width: 1500px) {
          }
          @media (max-width: 1200px) {
            font-size: 0.9rem;
          }
          @media (max-width: 1000px) {
            font-size: 0.8rem;
          }
          @media (max-width: 750px) {
            font-size: 0.7rem;
          }
          @media (max-width: 500px) {
            font-size: 0.7rem;
          }
        }
      }
      .inner-right {
        width: 30%;
        @media (max-width: 1500px) {
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 1000px) {
          width: 100%;
          display: block;
          margin-top: 2rem;
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
        button {
          font-weight: 600;
          font-size: 17px;
          @media (max-width: 1500px) {
          }
          @media (max-width: 1200px) {
          }
          @media (max-width: 1000px) {
            display: block;
          }
          @media (max-width: 750px) {
          }
          @media (max-width: 500px) {
          }
        }
      }
    }
  }
}
.v-combo >>> .v-combobox {
  box-shadow: none !important;
}
</style>
