import { BACKEND } from "../variables.js";
import axios from "axios";
const GET_ALL_PROPERTIES = BACKEND + "/property/getAllProperties";
const GET_ALL_HOUSES = BACKEND + "/property/getAllHouses";
const GET_ALL_STUDIOS = BACKEND + "/property/getAllStudios";
const GET_ALL_APARTMENTS = BACKEND + "/property/getAllApartments";
const GET_ALL_LANDS = BACKEND + "/property/getAllLands";
const CREATE_PROPERTY = BACKEND + "/property/createProperty";
const UPDATE_PROPERTY = BACKEND + "/property/updateProperty";
const DELETE_PROPERTY = BACKEND + "/property/deleteProperty";
const GET_PROPERTY = BACKEND + "/property/getProperty";
const GET_FILTERED_PROPERTIES = BACKEND + "/property/getFilteredProperties";
const GET_FEATURED_PROPERTIES = BACKEND + "/property/getFeaturedProperties";
const GET_RENTFEATURED_PROPERTIES =
  BACKEND + "/property/getRentFeaturedProperties";
const GET_SALEFEATURED_PROPERTIES =
  BACKEND + "/property/getSaleFeaturedProperties";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_ALL_PROPERTIES"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ALL_PROPERTIES + `?page=${payload.data.page}`,
        data: payload.data,
      });
    },
    ["GET_ALL_HOUSES"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ALL_HOUSES + `?page=${payload.data.page}`,
        data: payload.data,
      });
    },
    ["GET_ALL_STUDIOS"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ALL_STUDIOS + `?page=${payload.data.page}`,
        data: payload.data,
      });
    },
    ["GET_ALL_APARTMENTS"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ALL_APARTMENTS + `?page=${payload.data.page}`,
        data: payload.data,
      });
    },
    ["CREATE_PROPERTY"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_PROPERTY,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_PROPERTY"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_PROPERTY,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_PROPERTY"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_PROPERTY,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_PROPERTY"](state, payload) {
      return axios({
        method: "POST",
        url: GET_PROPERTY,
        data: payload.data,
      });
    },
    ["GET_ALL_LANDS"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ALL_LANDS + `?page=${payload.data.page}`,
        data: payload.data,
      });
    },
    ["GET_FILTERED_PROPERTIES"](state, payload) {
      return axios({
        method: "POST",
        url: GET_FILTERED_PROPERTIES + `?page=${payload.data.page}`,
        data: payload.data,
      });
    },
    ["GET_FEATURED_PROPERTIES"](state, payload) {
      return axios({
        method: "POST",
        url: GET_FEATURED_PROPERTIES + `?page=${payload.data.page}`,
        data: payload.data,
      });
    },
    ["GET_RENTFEATURED_PROPERTIES"](state, payload) {
      return axios({
        method: "POST",
        url: GET_RENTFEATURED_PROPERTIES + `?page=${payload.data.page}`,
        data: payload.data,
      });
    },
    ["GET_SALEFEATURED_PROPERTIES"](state, payload) {
      return axios({
        method: "POST",
        url: GET_SALEFEATURED_PROPERTIES + `?page=${payload.data.page}`,
        data: payload.data,
      });
    },
  },
};
