<template>
  <div class="login">
    <div class="login-section container">
      <img :src="$img('site.png')" alt="Logo" />
      <form action="" @submit.prevent="login">
        <h2>Login</h2>
        <div class="input-group">
          <div class="form-group">
            <input
              type="email"
              id="email"
              placeholder="Email"
              v-model="payload.email"
            />
          </div>
          <div class="form-group">
            <input
              type="password"
              id="password"
              placeholder="Password"
              v-model="payload.password"
            />
          </div>
        </div>
        <div class="input-group">
          <button type="submit" class="btn bp cw">Login</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      payload: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      this.$startLoader();
      this.$store
        .dispatch("ADMIN_LOGIN", this.payload)
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.$AdminLogin(res.data.admin, res.data.token);
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    document.title = "Admin Login";
  },
};
</script>

<style scoped lang="scss">
.login {
  .login-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    img {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      filter: blur(5px);
    }
    form {
      width: 100%;
      max-width: 400px;
      background: #ffffff79;
      padding: 40px;
      border-radius: 5px;
      box-shadow: 0 0 30px 0 #00000046;
      h2 {
        text-align: center;
        margin-bottom: 20px;
        color: #000;
      }
      .input-group {
        .form-group {
          margin-bottom: 20px;
          input {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            outline: none;
          }
        }
        button {
          width: 100%;
          padding: 10px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          color: #fff;
        }
      }
    }
  }
}
</style>