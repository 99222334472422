<template>
  <div class="Sidebar">
    <div class="sidebar-header">
      <img
        :src="$img('krealty.png')"
        alt="K-Realty Logo"
        @error="$imageurlalt()"
      />
    </div>
    <ul class="nav-links">
      <li>
        <router-link
          :to="{ name: 'AdminDashboard' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/dashboard') }"
        >
          <span>Overview</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminRequest' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/requests') }"
        >
          <span>Requests</span>
        </router-link>
      </li>
      <li>
        <p
          :to="{ name: 'AdminDashboard' }"
          class="nav-link nav-link-with-sublinks"
          :class="{ active: $urlContains('admin/properties') }"
          @click="toggleSubLinks('properties')"
        >
          <span>Properties</span>
        </p>
        <ul
          class="sub-links"
          :class="activePage == 'properties' ? 'active' : ''"
        >
          <li>
            <router-link
              :to="{ name: 'AdminHouses' }"
              class="nav-link"
              :class="{ active: $urlContains('admin/properties/houses') }"
            >
              <span>Houses</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'AdminStudios' }"
              class="nav-link"
              :class="{ active: $urlContains('admin/properties/studios') }"
            >
              <span>Studios</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'AdminApartments' }"
              class="nav-link"
              :class="{ active: $urlContains('admin/properties/apartments') }"
            >
              <span>Apartments</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'AdminLand' }"
              class="nav-link"
              :class="{ active: $urlContains('admin/properties/land') }"
            >
              <span>Land</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminLocations' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/locations') }"
        >
          <span>Locations</span>
        </router-link>
      </li>
      <li v-if="$getUser() && $getUser().type == 'main'">
        <router-link
          :to="{ name: 'AdminAdmins' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/admins') }"
        >
          <span>Admins</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminContacts' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/contacts') }"
        >
          <span>Messages</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminSettings' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/settings') }"
        >
          <span>Settings</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      userRole: "",
      activePage: "",
    };
  },
  methods: {
    toggleSubLinks(id) {
      if (this.activePage == id) {
        this.activePage = "";
      } else {
        this.activePage = id;
      }
    },
  },
  mounted() {
    if (this.$urlContains("admin/properties")) {
      this.activePage = "properties";
    }
  },
  watch: {
    $route(to, from) {
      this.activePage = null;
      if (this.$urlContains("admin/properties")) {
        this.activePage = "properties";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
.Sidebar {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    220deg,
    #16196e 0%,
    #162b72 50%,
    #1b508b 80%,
    #1461c5 100%
  );
  border-right: 1px solid white;
  border-right: 1px solid var(--neutral-gray-gray-50, #e5e8ee);
  box-shadow: 4px 0px 30px 0px rgba(0, 0, 0, 0.116);
  border-radius: 0 10px 10px 0;

  .sidebar-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 10px 0;
    img {
      width: 50%;
      max-width: 200px;
    }
  }
  .nav-links {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    li {
      width: 100%;
      display: flex;
      flex-direction: column;
      .nav-link {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 1rem;
        padding-left: 2rem;
        height: 50px;
        cursor: pointer;
        @media (max-width: 1500px) {
          padding: 0.5rem 1rem;
        }
        span {
          font-size: 0.92rem;
          font-weight: 400;
          color: #fff;
          text-transform: uppercase;
          @media (max-width: 1500px) {
            font-size: 0.8rem;
          }
        }
        &:hover,
        &.active {
          background-color: #c0efff23;
        }
        &.nav-link-with-sublinks {
          display: flex;
          justify-content: space-between;
          i {
            margin-left: auto;
            font-size: 0.8rem;
            color: var(--neutral-gray-gray-100, #c4c4c4);
            cursor: pointer;
          }
        }
      }
      .sub-links {
        width: 100%;
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
        background-color: #0009381e;
        &.active {
          height: 100%;
          li {
            .nav-link {
              padding-left: 3rem;
              color: #fff;
              border-left: 4px solid transparent;
              @media (max-width: 1500px) {
                padding: 0.5rem 1rem;
              }
              span {
                font-weight: 400;
                font-size: 0.9rem;
                @media (max-width: 1500px) {
                  font-size: 0.8rem;
                }
              }
              &:hover,
              &.active {
                background-color: #ccdeff17;
              }
            }
          }
        }
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>